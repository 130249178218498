import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { AddServiceModel } from '../../../service/models/apimodels/AddServiceModel';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { GetSimulationRequest } from '../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { GetViewInfoRequest } from '../../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { PhoneInfoModel } from '../add_phone_select/models/PhoneInfoModel';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { OptionConfirmMode } from './mode/OptionConfirmMode';
import { ConvertUtil } from '../../../common/util/ConvertUtil';
import { GetConstructionCostRequest } from '../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';


/**
 * 追加確認
 */
export class AddPhoneConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddPhoneConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr 	= null;	// 画面ウィザード管理クラス
	@WprModel('PhoneInfoModel')
	private m_PhoneInfo: PhoneInfoModel 			= null;	// Phoneサービス追加情報モデル
	@WprListData('OptionConfirmList')
	private m_OptionConfirmList: PhoneInfoModel[]	= null;	// オプション確認リスト
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_OptionConfirmMode = new OptionConfirmMode();	// OptionConfirmMode
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'PhoneInfoModel');
		this.addConfig('equipmentProviding', '機器の提供方法', 'PhoneInfoModel');
		this.addConfig('curFee', '旧月額', 'PhoneInfoModel', { converter: '3桁カンマ' });
		this.addConfig('newFee', '新月額', 'PhoneInfoModel', { converter: '3桁カンマ' });
		this.addConfig('total', '合計', 'PhoneInfoModel', { converter: '3桁カンマ' });
		this.addConfig('otokuPlan', '現在のプラン名', 'PhoneInfoModel');
		this.addConfig('location', '場所', 'PhoneInfoModel');
		this.addConfig('floor', '階数', 'PhoneInfoModel');
		this.addConfig('comment', 'フリー入力', 'PhoneInfoModel');
		this.addConfig('desiredDate1', '第一希望', 'PhoneInfoModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'PhoneInfoModel', { disabled: true });
		this.addConfig('desiredDate2', '第二希望', 'PhoneInfoModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'PhoneInfoModel', { disabled: true });
		this.addConfig('desiredDate3', '第三希望', 'PhoneInfoModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'PhoneInfoModel', { disabled: true });
		this.addConfig('cost', '工事費', 'PhoneInfoModel', { converter: '3桁カンマ' });
		this.addConfig('payUnit', '金額単位', 'PhoneInfoModel');
		this.addListConfig('optionName', 'オプション名', 'OptionConfirmList');
		this.addConfig('beforeContact', '前日連絡先', 'PhoneInfoModel');
		this.addConfig('sameDayContact', '当日連絡先', 'PhoneInfoModel');
		this.addConfig('name', '当日立ち合い者', 'PhoneInfoModel');
		this.addConfig('reformDisp', 'リフォーム', 'PhoneInfoModel');
		this.addConfig('securityDisp', 'セキュリティ会社', 'PhoneInfoModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_PhoneInfo = this.m_MypageWizardMngr.getCache().m_PhoneInfo;
			const customer = this.m_MypageWizardMngr.getViewInfo().customer;
			//リフォーム
			if (this.m_PhoneInfo.reformFlg)
				this.m_PhoneInfo.reformDisp = 'あり';
			else
				this.m_PhoneInfo.reformDisp = 'なし';
			//セキュリティ会社
			if (this.m_PhoneInfo.securityFlg)
				this.m_PhoneInfo.securityDisp = this.m_PhoneInfo.securityComment;
			else
				this.m_PhoneInfo.securityDisp = 'なし';
			if (customer.building[0].buildingTypeCD === '3' || customer.building[0].buildingTypeCD === '2') {
				this.setViewMode('階数', '表示');
				this.m_PhoneInfo.cost = 'お見積り';
			}
			else {
				const req: GetConstructionCostRequest = new GetConstructionCostRequest();
				req.constructionCategoryCd = '02';
				req.applicationDetailsCd = '01';
				req.temporaryCostCategoryCd = '01';
				req.multipleFlg = false;
				req.freeFlg = false;
				this.m_MypageWizardMngr.getConstructionCost(this, req, null, false, false, result => {
					if (result) {
						if (result.temporaryCost.length > 0) {
							this.m_PhoneInfo.cost = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost);
							this.m_PhoneInfo.payUnit = '円';
						}
						else
							this.m_PhoneInfo.cost = 'お見積り';
						this.refreshView();
					}
				});
			}
			this.setOptionConfirmList();
			this.setOtoku();			// お得パックの表示切替
			this.paymentSimulation();	// 料金シミュレーション
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.getCache().m_OptionConfirmList = new Array();
		this.m_PhoneInfo.cost = null;
		this.m_PhoneInfo.payUnit = null;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// private変数  ---------------------------------------------------------------
	/**
	 * 料金シミュレーション
	 */
	private paymentSimulation(): void {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();
		const phoneService = new AddServiceModel;
		phoneService.serviceCd = this.m_PhoneInfo.serviceCd;
		phoneService.rentalFlg = '1';
		const optionList = this.m_MypageWizardMngr.getCache().m_OptionListModel.filter(row => row.check);
		if (optionList && optionList.length > 0) {
			phoneService.optionCdList = new Array();
			for (const option of optionList)
				phoneService.optionCdList.push(option.subCd2);
		}
		req.addServiceList.push(phoneService);
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_PhoneInfo.curFee = ConvertUtil.convertToComma(result.breforeFee);
			this.m_PhoneInfo.newFee = result.afterFee;
			for (const data of result.detailList) {
				if (this.m_PhoneInfo.serviceCd === data.serviceCd) {
					const price: number = Number(data.detailPrice);
					const tax: number = Number(data.detailTax);
					this.m_PhoneInfo.total = String(price + tax);
				}
			}
			this.refreshView();
		});
	}

	private setOtoku(): void {
		if (!this.m_PhoneInfo.otokuFlg && this.m_PhoneInfo.noOtoku) {
			this.setViewMode('お得パック', '希望しない')	// お得パック希望しない用画面表示
		}
		else if (!this.m_PhoneInfo.otokuFlg && this.m_PhoneInfo.yesOtoku) {
			this.setViewMode('お得パック', '希望する');		// お得パック希望者用画面表示
		}
		else if (this.m_PhoneInfo.otokuFlg) {
			this.setViewMode('お得パック', '加入者');		// お得パック加入者用画面表示
		}
		else if (!this.m_PhoneInfo.otokuFlg) {
			this.setViewMode('お得パック', '希望しない');	// お得パック未加入者かつお得パック選択画面非表示
		}
	}

	/**
	 * オプション確認リスト設定
	 */
	private setOptionConfirmList() {
		this.m_OptionConfirmList = this.m_MypageWizardMngr.getCache().m_OptionConfirmList;
		if (this.m_OptionConfirmList.length === 0) {
			const optionList = this.m_MypageWizardMngr.getCache().m_OptionListModel.filter(row => row.check);
			if (optionList && optionList.length > 0) {
				for (const option of optionList) {
					const model = new PhoneInfoModel();
					model.optionName = option.optionName;
					this.m_OptionConfirmList.push(model);
				}
			}
		}
		this.setViewData('sentenceData', this.m_OptionConfirmList.length + 1);
		this.setListData('OptionConfirmList', this.m_OptionConfirmList);
	}
	// --------------------------------------------------------------------------
}
