import { WprModel } from '../../../wpr-framework/view/deco/WprModel';
import { IWprUrlParam } from '../../../wpr-framework/view/route/IWprUrlParam';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { GetViewInfoResponse } from '../../service/models/dto/mypagerenewal/GetViewInfoResponse';
import { WizardPostModel } from '../../models/WizardModel';
import { WprViewMode } from '../../../wpr-framework/view/deco/WprViewMode';
import { ViewPostMode } from './mode/ViewPostMode';
import { WprEventName } from '../../../wpr-framework/action/WprEventActionInfo';
import { WprDI_Control } from '../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../wpr-framework/view/control/WprControlInfo';
import { WprKeyValue } from '../../../wpr-framework/model/WprKeyValue';
import { CryptRijndaelUtil } from '../../common/util/CryptRijndaelUtil';
import { ObjectUtil } from '../../common/util/ObjectUtil';

/**
 * メニュー（仮）
 */
export class MenuView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('Menu'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_contractId: WprControlInfo	= null;	// 契約ID
	@WprDI_Control
	private m_optionId: WprControlInfo	= null;	// オプションID
	// --------------------------------------------------------------------------

	// private 変数  ------------------------------------------------------------
	private m_ViewInfo: GetViewInfoResponse	= null;	// 検索結果
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('parameter')
	private m_parameter: string = null;		// パラメータ
	@WprModel('searchResult')
	private m_searchResult: string	= null;	// searchResult
	@WprModel('m_WizardPostModel')
	private m_WizardPostModel: WizardPostModel	= null;	// 画面ウィザードPOSTデータ格納モデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_ViewPostMode = new ViewPostMode();	// ViewへGETパラメータで付与するデータを制御
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('customerId', '顧客ID', 'm_WizardPostModel');
		this.addConfig('contractId', '契約ID', 'm_WizardPostModel');
		this.addConfig('optionId', 'オプションID', 'm_WizardPostModel');
		this.addConfig('parameter', 'パラメータ', 'parameter', { bindModel: true });
		this.addConfig('service', 'ビューID', 'm_WizardPostModel', { bindName: 'viewId' });
		this.addConfig('searchResult', 'searchResult', 'searchResult', { bindModel: true });
		this.addConfig('tid', '機器番号', 'm_WizardPostModel');
		this.addConfig('stcd', '在庫種別', 'm_WizardPostModel');
		this.addConfig('ttcd', '機器タイプ', 'm_WizardPostModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('searchViewInfo', this.onSearchViewInfo);
		this.addAction('ContractDetails', this.onContractDetails);
		this.addAction('SimDataENtry', this.onSimDataENtry);
		this.addAction('Ambassador', this.onAmbassador);
		this.addAction('ContractList', this.onContractList);
		this.addAction('CapacityChange', this.onCapacityChange);
		this.addAction('MnpInquiry', this.onMnpInquiry);
		this.addAction('MvnoAddCoupon', this.onMvnoAddCoupon);
		this.addAction('UsageDetails', this.onUsageDetails);
		this.addAction('ServiceList', this.onServiceList);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		// 開発環境でのみ有効な画面
		const host = location.host;
		if (!host.includes("localhost"))
			this.pushPath('/error');

		this.m_WizardPostModel = new WizardPostModel();
		// this.m_WizardPostModel.customerId = '19870490389';
		this.m_WizardPostModel.customerId = '20000609969'; 
		// this.m_WizardPostModel.customerId = '19910450400';// 集合
		this.m_WizardPostModel.customerId = '20021100930';// 戸建賃貸FD対象　コース変更（FTTH）
		// this.m_WizardPostModel.customerId = '19870460002';// 戸建賃貸FD対象　増設（HFC）
		// this.m_WizardPostModel.customerId = '19000100717'; 
		// this.m_WizardPostModel.customerId = '80002053418';	// 法人
		// this.m_WizardPostModel.customerId = '80001770904'; // 戸建賃貸FD対象外 増設
		// this.m_WizardPostModel.customerId = '51000406223'; //  戸建賃貸FD対象外 テレビ機器変更
		this.m_WizardPostModel.customerId = '51000709075';	// 戸建賃貸FD対象外　コース変更
		// this.m_WizardPostModel.customerId = '20030584190';
		this.m_parameter = 'SESSIONID=3da5c9c1bcb49371c64b40da3f40ea4e&AUTHTOKEN=8c2c40d6b5f6dab7aa88af270b6d0ebd';
		this.refreshView();
		// CryptRijndaelUtil.test();
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'service') {
			this.refreshModel();
			const mode = this.getViewMode('ビュー送信データ制御モード');
			// 何かしらのテキストが表示されている
			if (mode.length > 1 && !mode.includes('機器番号送信')) {
				const req = new GetViewInfoRequest();
				req.customerId = this.m_WizardPostModel.customerId;
				req.contractFlg = true;
				req.paymentFlg = true;
				this.getViewInfo(this, req, (result: GetViewInfoResponse) => {
					this.m_ViewInfo = result;
					this.m_searchResult = JSON.stringify(result, null, '　');
					this.m_searchResult = this.m_searchResult.replace(/\r?\n/g, '<br/>');
					this.refreshView();
					this.m_contractId.resetValueMap();
					this.m_optionId.resetValueMap();
				});
			}
		}
		return true;
	}

	/**
	 * ValueMap再設定処理
	 * @param name コントロール名
	 * @param id ValueMapID
	 * @list ValueMapのKey-Valueリスト
	 * @returns 再設定したKey-Valueリスト
	 */
	public onResetValueMap(name: string, id: string, list: WprKeyValue[]): WprKeyValue[] {
		const newList: WprKeyValue[] = [];
		if (name === 'contractId') {
			this.m_ViewInfo?.viewModel.contractModel.contractList.forEach(contract => {
				newList.push(new WprKeyValue(contract.contractID,
					'契約ID：' + contract.contractID + ', タイプ：' + contract.serviceTypeCD + ', コード：' + contract.serviceCD));
			});
			return newList;
		}
		if (name === 'optionId') {
			if (this.m_WizardPostModel?.viewId === 'mod_ipop_confirm') {
				newList.push(new WprKeyValue('0202000110', 'グローバルIP：0202000110'));
				newList.push(new WprKeyValue('9999999999', 'グローバルIP：9999999999'));
				newList.push(new WprKeyValue('OP00000009', 'プライベートIP：OP00000009'));
				return newList;
			}
			// if (this.m_WizardPostModel?.viewId === 'name_change_information') {
			// 	newList.push(new WprKeyValue('2', '旧名義人：2'));
			// 	return newList;
			// }
			// if (this.m_WizardPostModel?.viewId === 'name_change_form') {
			// 	newList.push(new WprKeyValue('1', '新名義人：1'));
			// 	return newList;
			// }
		}
		return list;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 契約内容確認へ
	 */
	public onSearchViewInfo(param: any): void {
		this.refreshModel();
		const req = new GetViewInfoRequest();
		req.customerId = this.m_WizardPostModel.customerId;
		req.displayList = new Array();
		const filter = new MDisplay();
		filter.dispdivcd = 'CD0096';
		filter.namecd = '0804000001';
		req.displayList.push(filter);
		req.yakkanList = new Array();
		const filter2 = new MGeneral();
		filter2.codeTypeCd = '010'
		req.yakkanList.push(filter2);
		req.commonList = new Array();
		req.commonList.push(new MCommon('見放題パック月額', 'srvidp', 'prime_code', null, null));
		req.contractFlg = true;
		req.paymentFlg = true;
		this.getViewInfo(this, req, (result: GetViewInfoResponse) => {
			this.m_searchResult = JSON.stringify(result, null, '　');
			this.m_searchResult = this.m_searchResult.replace(/\r?\n/g, '<br/>');
			this.refreshView();
		});
	}

	/**
	 * 契約内容確認へ
	 */
	public onContractDetails(param: any): void {
		const paramList = this.setParameter();
		paramList.push({name: WizardPostModel.URL_VIEW_ID, value: 'CONTRACT_CONFIRM'});
		this.pushPath('/', paramList);
	}

	/**
	 * 音声SIM・データ申込へ
	 */
	public onSimDataENtry(param: any): void {
		const paramList = this.setParameter();
		paramList.push({name: WizardPostModel.URL_VIEW_ID, value: 'MVNO_ENTRY'});
		this.pushPath('/', paramList);
	}

	/**
	 * MVNO容量変更へ
	 */
	public onCapacityChange(param: any): void {
		const paramList: IWprUrlParam[] = this.setParameter();
		paramList.push({name: WizardPostModel.URL_VIEW_ID, value: 'MVNO_CAPACITYCHANGE'});
		paramList.push({name: WizardPostModel.URL_CONTRACT_ID, value: this.m_WizardPostModel.contractId});
		this.pushPath('/', paramList);
	}

	/**
	 * 契約内容一覧へ
	 */
	public onMnpInquiry(param: any): void {
		const paramList = this.setParameter();
		paramList.push({name: WizardPostModel.URL_VIEW_ID, value: 'MVNO_MNP'});
		this.pushPath('/', paramList);
	}

	/**
	 * クーポン対応（データ容量追加申込）
	 */
	public onMvnoAddCoupon(param: any): void {
		const paramList: IWprUrlParam[] = this.setParameter();
		paramList.push({name: WizardPostModel.URL_VIEW_ID, value: 'MVNO_ADDCOUPON'});
		paramList.push({name: WizardPostModel.URL_CONTRACT_ID, value: this.m_WizardPostModel.contractId});
		this.pushPath('/', paramList);
	}

	/**
	 * アンバサダーへ
	 */
	public onAmbassador(param: any): void {
		this.pushPath('/ambassador_001');
	}

	/**
	 * 利用明細へ
	 */
	public onUsageDetails(param: any): void {
		this.pushPath('/usage_details_001');
	}

	/**
	 * 契約内容一覧へ
	 */
	public onContractList(param: any): void {
		this.pushPath('');
	}

	/**
	 * サービス変更へ
	 */
	public onServiceList(param: any): void {
		this.refreshModel();
		if (this.m_WizardPostModel.viewId) {
			if (ObjectUtil.isNotNullOrEmptyStr(this.m_WizardPostModel.contractId)) {
				this.setCookie('contractId', this.m_WizardPostModel.contractId, '/', 3600);
			}
			let paramList: IWprUrlParam[] = null
			if (this.m_parameter) {
				paramList = this.setParameter();
			} else {
				paramList = new Array();
			}

			// URLで渡す代わりにセッションへ詰める
			this.setCookie(WizardPostModel.COOKIE_CUSTOMER_ID, this.m_WizardPostModel.customerId, '/', 3600);
			// URLで渡さないパラメータ
			// if(this.m_WizardPostModel.customerId) {
			// 	paramList.push({name: WizardPostModel.CONST_CUSTOMER_ID, value: this.m_WizardPostModel.customerId});
			// }

			if (this.m_WizardPostModel.contractId) {
				paramList.push({name: WizardPostModel.URL_CONTRACT_ID, value: this.m_WizardPostModel.contractId.slice(-5)});
			}
			if (this.m_WizardPostModel.optionId) {
				paramList.push({name: WizardPostModel.URL_OPTION_ID, value: this.m_WizardPostModel.optionId});
			}
			if (this.m_WizardPostModel.tid) {
				paramList.push({name: WizardPostModel.URL_TERMINAL_ID, value: this.m_WizardPostModel.tid});
			}
			if (this.m_WizardPostModel.stcd) {
				paramList.push({name: WizardPostModel.URL_STOCK_TYPE_CD, value: this.m_WizardPostModel.stcd});
			}
			if (this.m_WizardPostModel.ttcd) {
				paramList.push({name: WizardPostModel.URL_TERMINAL_TYPE_CD, value: this.m_WizardPostModel.ttcd});
			}
			paramList.push({name: WizardPostModel.URL_VIEW_ID, value: this.m_WizardPostModel.viewId});
			this.pushPath('/', paramList);
		}
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	private setParameter(): IWprUrlParam[] {
		this.refreshModel();
		const paramList = this.m_parameter.split('&');
		const newParamList: IWprUrlParam[] = new Array();
		paramList?.forEach(param => {
			const paramName: string = param.split('=')[0];
			const paramVal: string = param.split('=')[1];
			newParamList.push({name: paramName, value: paramVal});
		});
		return newParamList;
	}
	// --------------------------------------------------------------------------
}
