import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { NameChangeInformationView } from '../name_change/name_change_information/NameChangeInformationView';
import { NameChangeFormView } from '../name_change/name_change_form/NameChangeFormView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { NameChangeIdentificationView } from '../name_change/name_change_identification/NameChangeIdentificationView';
import { NameChangeConfirmView } from '../name_change/name_change_confirm/NameChangeConfirmView';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { WizardModel } from '../../models/WizardModel';
import { NameChangeAppModel } from '../../service/models/apimodels/NameChangeAppModel';
import { NameChangeCompleteView } from '../name_change/name_change_complete/NameChangeCompleteView';
import { ViewInfoModel } from '../../service/models/apimodels/ViewInfoModel';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { NameChangeInfo } from '../../service/models/apimodels/NameChangeInfo';
import { NameChangePayment } from '../../service/models/apimodels/NameChangePayment';
import { BssConstants } from '../../common/constants/BssConstants';
import { Phoneconst } from '../Phone/add_phone_select/Phoneconst';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';

/**
 * 名義変更ウィザードクラス
 */
export class NameChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_nonVerifyWizardFlow: WizardModel[]		= null;		// 改姓
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		// 旧名義人パターン
		this.m_StartViewName = new NameChangeInformationView().name;
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Cust_mod/Continfo_disp/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '事前確認事項', viewName: this.m_StartViewName, path: 'name_change_information'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '名義変更入力', viewName: new NameChangeFormView().name, path: 'name_change_form'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '約款確認', viewName: new CommonAgreeView().name, path: 'name_change_agree'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '本人確認', viewName: new NameChangeIdentificationView().name, path: 'name_change_identification'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み内容確認', viewName: new NameChangeConfirmView().name, path: 'name_change_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 6, title: '申込み完了', viewName: new NameChangeCompleteView().name, path: 'name_change_complete'});

		// 改姓のパターン
		this.m_nonVerifyWizardFlow = new Array();
		this.m_nonVerifyWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_nonVerifyWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_nonVerifyWizardFlow.push(this.m_DefaultWizardFlow[2]);
		this.m_nonVerifyWizardFlow.push(this.m_DefaultWizardFlow[3]);
		this.m_nonVerifyWizardFlow.push({stepNum: 4, title: '申込み内容確認', viewName: new NameChangeConfirmView().name, path: 'name_change_confirm'});
		this.m_nonVerifyWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new NameChangeCompleteView().name, path: 'name_change_complete'});

	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		let req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		const nameChengeApiModel = new NameChangeApiModel();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === '名義変更_旧';
		});
		// お客様メール
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		// 問合せ
		if (cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn === '1')
			req = nameChengeApiModel.createInquiry(view, cacheData, req);
		// 業務部門メール　※現状、メール送信なしのためコメントアウト※
		// req.systemInfo.paymentDispname = '';
		// req.systemInfo.changedAddr = '';
		return req;
	}

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: NameChangeApiModel = mngr.getCache();
		const kbn = cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn
		// 改姓の場合
		if (kbn && kbn === '1') 
			return this.m_nonVerifyWizardFlow;
		else
			return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.serviceLineFlg = true;

		/*旧名義人 */
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_MAIJU_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_FTTH_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME, null));
		req.commonList = new Array();
		req.commonList.push(new MCommon('テレビプッシュ', 'srvsmart', 'tvpush'));
		const kattobiList:string[] = ['fG', 'fHS', 'fT', 'mG', 'mHS', 'mT'];
		for(const kattobi of kattobiList) {
			const common2 = new MCommon('かっとび光', 'namecd', 'course_net', kattobi);
			req.commonList.push(common2);
		}
		req.commonList.push(new MCommon('まいにち充実プラン（パック）', 'otoku', 'new_plan_cd'));
		req.commonList.push(new MCommon('まいにち充実プラン（TV）', 'tv_service', 'new_plan'));
		req.commonList.push(new MCommon('快適メッシュWi-Fi', 'srvother', 'kaiteki_mesh_wifi'));
		// かっとびバリュー
		req.commonList.push(new MCommon('かっとびバリュー', 'namecd', 'course_net', 'value'));
		req.commonList.push(new MCommon('WOWWOWサービス', 'opt' , 'digital_wowow'));
		req.commonList.push(new MCommon('NHKサービス', 'srvother' , 'nhk'));
		if (mngr.getViewInfo() !== null) {
			/*約款出しわけ処理 */
			const contractModel = mngr.getViewInfo().contractModel;
			const customer = mngr.getViewInfo().customer;
			req.yakkanList = new Array();
			let yakkan_1 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE);
			let yakkan_3 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_HIKARI_TV);
			let yakkan_2 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_PRICE);
			let yakkan_4 : YakkanModel = new YakkanModel(MypageYakkanConstants.CABLETV_CATV);
			let yakkan_5 : YakkanModel = new YakkanModel(MypageYakkanConstants.CABLETV_BCAS);
			let yakkan_6 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_UNLIMITED);
			let yakkan_7 : YakkanModel = new YakkanModel(MypageYakkanConstants.INTENET_CATV);
			let yakkan_8 : YakkanModel = new YakkanModel(MypageYakkanConstants.IMPORTANT_HIKARI);
			let yakkan_9 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_HIKARI_INTERNET);
			let yakkan_10 : YakkanModel = new YakkanModel(MypageYakkanConstants.PHONE_CABLEPLUS);
			let yakkan_11 : YakkanModel = new YakkanModel(MypageYakkanConstants.IMPORTANT_ITSCOMHOME);
			let yakkan_12 : YakkanModel = new YakkanModel(MypageYakkanConstants.TV_PUSH);
			let yakkan_13 : YakkanModel = new YakkanModel(MypageYakkanConstants.MESH_WIFI)
			let yakkan_14 : YakkanModel = new YakkanModel(MypageYakkanConstants.ENERGY_DENKI_GAS);
			let yakkan_15 : YakkanModel = new YakkanModel(MypageYakkanConstants.ENERGY_IMPORTANT);
			let yakkan_16 : YakkanModel = new YakkanModel(MypageYakkanConstants.ENERGY_TRADE_LAW);
			let yakkan_17 : YakkanModel = new YakkanModel(MypageYakkanConstants.KATTOBI_SERVER);
			let yakkan_18 : YakkanModel = new YakkanModel(MypageYakkanConstants.REGULAR_CONTRACT);
			let yakkan_19 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT);

			for(const contract of contractModel.contractList) {
				/*テレビ */
				if ((contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_TVF) 
					|| (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_TV)) {
						yakkan_19.select = true;
						yakkan_1.select = true;
						yakkan_2.select = true;
						// テレビまい充
						const maiju = mngr.getMCommonList(view, 'tv_service', 'new_plan', null, null);
						const maijuServiceList = (mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_MAIJU_SERVICE, '').concat(mngr.getMDisplayList(view, MypageDispdivcdConstants.TV_FTTH_SERVICE, ''))).filter(row => row.dispnamep === maiju[0].commonValue);
						if (maijuServiceList.filter(data => data.namecd === contract.serviceCD).length > 0) {
							yakkan_3.select = true;
							yakkan_6.select = true;
						}
						else {
							const servive = mngr.getViewInfo().serviceLineList.filter(data => data.servicecd === contract.serviceCD && data.servicetype === '010');
							if (servive && servive.length > 0) {
								if (servive[0].linetype === 'FTTH') {
									yakkan_3.select = true;
									yakkan_5.select = true;
									yakkan_6.select = true;
								}
								else if (servive[0].linetype === 'HFC') {
									yakkan_4.select = true;
									yakkan_5.select = true;
								}
							}
						}
					}
				/*ネット */
				if ((contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NETF) 
					|| (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_NET)) {
						yakkan_19.select = true;
						yakkan_1.select = true;
						yakkan_2.select = true;
						const service = mngr.getViewInfo().serviceLineList.filter(data => data.servicecd === contract.serviceCD && data.servicetype === '020');
						if (service && service.length > 0) {
							if(service[0].linetype === 'FTTH')
								/* ネット約款FTTH */
								yakkan_9.select = true;
							else if (service[0].linetype === 'HFC') {
								const mCommonData : MCommon = mngr.getMCommonByValue(view, 'namecd', 'course_net', null, null, contract.serviceCD);
								if (mCommonData) {
									if (mCommonData.category3 === 'value') 
										/*かっとびサーバ利用型サービス契約約款 */
										yakkan_17.select = true;
									else
										/*ネット約款かっとび光 */
										yakkan_8.select = true;
								}
								else
									/* ネット約款通常 */
									yakkan_7.select = true;
							}
						}
					}
				/*ケーブルプラス電話 */
				if ((contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_KDDI) 
					|| (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_PHONEF)) {
					yakkan_19.select = true;
					yakkan_2.select = true;
					yakkan_10.select = true;
				}
				/*iTSCOM SMART */
				if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_IH) {
					yakkan_19.select = true;
					yakkan_1.select = true;
					yakkan_2.select = true;
					if (mngr.getMCommonByValue(view, 'srvsmart', 'tvpush', null, null, contract.serviceCD))
						/*テレビプッシュ */
						yakkan_12.select = true;
					else
						/*イッツコムホーム */
						yakkan_11.select = true;
				}
				/* 東急でんきガス */
				if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_ENERGY) {
					yakkan_19.select = true;
					yakkan_1.select = true;
					yakkan_2.select = true;
					yakkan_14.select = true;
					yakkan_15.select = true;
					yakkan_16.select = true;
				}
				/* その他 */
				if (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_OTHER) {
					/* メッシュWi-Fi */
					const mesh = (mngr.getMCommonList(view, 'srvother', 'kaiteki_mesh_wifi', null, null)).filter(data => data.commonValue === contract.serviceCD);
					if (mesh && mesh.length > 0) {
						yakkan_19.select = true;
						yakkan_13.select = true;
					}
					/* まいにち充実プラン */
					if (mngr.getMCommonByValue(view, 'otoku', 'new_plan_cd', null, null, contract.serviceCD))
						yakkan_18.select = true;
				}
			}
			const yakkanModelList : YakkanModel[] = new Array();
			yakkanModelList.push(yakkan_19);
			yakkanModelList.push(yakkan_1);
			yakkanModelList.push(yakkan_3);
			yakkanModelList.push(yakkan_4);
			yakkanModelList.push(yakkan_5);
			yakkanModelList.push(yakkan_6);
			yakkanModelList.push(yakkan_7);
			yakkanModelList.push(yakkan_8);
			yakkanModelList.push(yakkan_9);
			yakkanModelList.push(yakkan_10);
			yakkanModelList.push(yakkan_11);
			yakkanModelList.push(yakkan_12);
			yakkanModelList.push(yakkan_13);
			yakkanModelList.push(yakkan_14);
			yakkanModelList.push(yakkan_15);
			yakkanModelList.push(yakkan_16);
			yakkanModelList.push(yakkan_17);
			yakkanModelList.push(yakkan_18);
			yakkanModelList.push(yakkan_2);
			for (const yakkanModel of yakkanModelList) {
				if (yakkanModel.select) {
					req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, yakkanModel.yakkanNum));
				}
			}
			// 表示する約款がない場合
			if (req.yakkanList.length === 0) {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
			}
		}
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): NameChangeApiModel {
		let cacheData = new NameChangeApiModel();
		cacheData.nameChangeAppInfo = new NameChangeAppModel();
		cacheData.nameChangeAppInfo.nameChangeInfo = new NameChangeInfo();
		cacheData.nameChangeAppInfo.customerId = mngr.getParams().customerId;
		// cacheData.nameChangeAppInfo.newCustomerFlg = false;
		return cacheData;
	}
	
}

/**
 * 画面ウィザードAPI用モデル
 */
 export class NameChangeApiModel {
	// public 変数	-----------------------------------------------------------
		public nameChangeAppInfo: NameChangeAppModel	= null;
	// ------------------------------------------------------------------------
	
	// public メソッド	-------------------------------------------------------
	// NHK加入者判定
	public isNhk(view: MypageBaseView) :string {
		const contractList = view.mngr.getViewInfo().contractModel.contractList;
		if (contractList && contractList.length > 0) {
			const nhk = contractList.filter(data =>  data.serviceCD === view.mngr.getMCommonList( view, 'srvother' , 'nhk', null , null)[0].commonValue);
			if (nhk && nhk.length > 0)
				return '加入';
			else
				return '未加入';
		}
		return '未加入';
	}

	// WOWOW加入者判定
	public isWowwow(view: MypageBaseView) :string {
		const contractList = view.mngr.getViewInfo().contractModel.contractList;
		const tvTypeList: string[] = [BssConstants.BSS_CONTRACT_SERVICETYPECD_TVF, BssConstants.BSS_CONTRACT_SERVICETYPECD_TV];
		const wowwow = view.mngr.getMCommonList( view, 'opt' , 'digital_wowow', null , null);
		for (const contract of contractList) {
			if (tvTypeList.includes(contract.serviceTypeCD)) {
				for (const opt of contract.option) {
					if (opt.optionCD === wowwow[0].commonValue)
						return '加入';
				}
			}
			else
				continue;
		}
		return '未加入';
	}

	// 問合せ作成
	public createInquiry(view: MypageBaseView, cacheData: NameChangeApiModel, req: RegistInfoRequest) :RegistInfoRequest {
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === '名義変更_旧';
		});
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		const kbn = cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn;
		if (kbn === '1') {
			req.systemInfo.nameChangeType = '苗字の変更（改姓）';
			req.systemInfo.escalationFlg = true;
		}
		else if (kbn === '2') 
			req.systemInfo.nameChangeType = 'ご契約者さま逝去';
		else if (kbn === '3') 
			req.systemInfo.nameChangeType = '同居のご家族への契約者変更';
		req.systemInfo.enteredName = cacheData.nameChangeAppInfo.nameChangeInfo.newName;
		req.systemInfo.enteredNameKana = cacheData.nameChangeAppInfo.nameChangeInfo.newNameKana;
		req.systemInfo.relationDispName = cacheData.nameChangeAppInfo.nameChangeInfo.relationShip;
		if (cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn === '1') {
			req.systemInfo.sex = '';
			req.systemInfo.birthday = '';
		}
		else {
			req.systemInfo.sex = cacheData.nameChangeAppInfo.nameChangeInfo.gender;
			const birthday = cacheData.nameChangeAppInfo.nameChangeInfo.birthday;
			req.systemInfo.birthday = birthday.substring(0,4) + '年' + birthday.substring(4,6) + '月' + birthday.substring(6,8) + '日';
		}
		req.systemInfo.enteredTel1 = cacheData.nameChangeAppInfo.nameChangeInfo.phoneNumber1;
		req.systemInfo.enteredTel2 = cacheData.nameChangeAppInfo.nameChangeInfo.phoneNumber2;
		req.systemInfo.newMail = cacheData.nameChangeAppInfo.nameChangeInfo.newMail;
		return req;
	}
	// ------------------------------------------------------------------------

}

/**
 * 約款モデル
 */
 export class YakkanModel {
	// public 変数	-----------------------------------------------------------
		public yakkanNum: string = null;
		public select: boolean = false;
	// ------------------------------------------------------------------------
	public constructor(num : string) {
		this.yakkanNum = num;
	}
}


