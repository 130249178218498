import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { WprNumberValidator } from '../../../../wpr-framework/view/validation/validator/WprNumberValidator';
import { WprZenkakuValidator } from '../../../../wpr-framework/view/validation/validator/WprZenkakuValidator';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { Customer } from '../../../models/bss/customer/Customer';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';
import { BaseService } from '../../../service/base/BaseService';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { GetNameChangeRequest } from '../../../service/models/dto/mypagerenewal/GetNameChangeRequest';
import { RegistInfoRequest } from '../../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { NameChangeService } from '../../../service/NameChangeService';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { DateConverter_JPConverter } from '../../framework/Converter/DateConverter_JPConverter';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { NameChangeNewApiModel } from '../../wizard/NameChangeNewWizardFlow';

/**
 * 新名義人受付フォーム
 */
export class NameChangeNewFormView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeNewFormView'); }
	// --------------------------------------------------------------------------
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_ChangePaymentFlg: WprControlInfo	= null;	// 支払方法変更フラグ
	@WprDI_Control
	private m_CreditFlg: WprControlInfo	= null;	// 支払方法クレカフラグ
	@WprDI_Control
	private m_ContractAddressFlg: WprControlInfo	= null;	// 契約住所に送付フラグ
	@WprDI_Control
	private m_PaymentAddress: WprControlInfo	= null;	// 口座送付先住所
	@WprDI_Control
	private m_PaymentBuilding: WprControlInfo	= null;	// 口座送付先建物名
	@WprDI_Control
	private m_PaymentRoomNum: WprControlInfo	= null;	// 口座送付先部屋番号
	@WprDI_Control
	private m_paymentPostCode: WprControlInfo	= null;	// 送付先郵便番号
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprListData('ErrorModel')
	private m_ErrorModelList: ErrorModel[]	= null;			// エラーモデル
	@WprModel('titleComment')
	private m_TitleComment: string = null;					// タイトルコメント
	// @WprModel('nextBtnComment')
	// private m_NextBtnComment: string = null;					// nextボタン文言
	private m_Customer: Customer = null;					// 顧客情報

	private m_NameChangeService: NameChangeService	= new NameChangeService();	// 名義変更サービス

	private m_ZenkakuValidator: WprZenkakuValidator = new WprZenkakuValidator();	//全角バリデーター
	private m_NumberValidator: WprNumberValidator = new WprNumberValidator();	//整数バリデーター
	private m_DateConverter_JPConverter: DateConverter_JPConverter = new DateConverter_JPConverter();	//日付コンバーター
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 共通------------------------------------
		this.addConfig('nameChangeKbn', '名義変更区分', 'NameChangeAppModel.nameChangeInfo', { converter: '名義変更区分' });
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModel');
		this.addConfig('titleComment', 'タイトルコメント', 'titleComment', { bindModel: true });
		// this.addConfig('nextBtnComment', 'nextボタン文言', 'nextBtnComment', { bindModel: true });
		
		// 新名義人パターン-------------------------
		// 旧名義人入力情報
		this.addConfig('c_NewName', '新名義人名', 'NameChangeAppModel.nameChangeInfo', { bindName: 'newName'});
		this.addConfig('c_NewNameKana', '新名義人名カナ', 'NameChangeAppModel.nameChangeInfo', { bindName: 'newNameKana'});
		this.addConfig('c_RelationShip', '続柄', 'NameChangeAppModel.nameChangeInfo', { bindName: 'relationShip'});
		this.addConfig('c_Gender', '性別', 'NameChangeAppModel.nameChangeInfo', { bindName: 'gender'});
		this.addConfig('birthdayStr', '生年月日', 'NameChangeAppModel');
		this.addConfig('c_PhoneNumber1', '電話番号1', 'NameChangeAppModel.nameChangeInfo', { bindName: 'phoneNumber1'});
		this.addConfig('c_PhoneNumber2', '電話番号2', 'NameChangeAppModel.nameChangeInfo', { bindName: 'phoneNumber2'});
		this.addConfig('c_NewMail', 'メールアドレス', 'NameChangeAppModel.nameChangeInfo', { bindName: 'newMail'});
		// 支払情報
		this.addConfig('changePaymentFlg', '支払方法変更フラグ', 'NameChangeAppModel.nameChangePayment');
		this.addConfig('creditFlg', '支払方法クレカフラグ', 'NameChangeAppModel.nameChangePayment');
		this.addConfig('contractAddressFlg', '契約住所に送付フラグ', 'NameChangeAppModel.nameChangePayment');
		this.addConfig('paymentAddress', '口座送付先住所', 'NameChangeAppModel', { maxLength: 50});
		this.addConfig('paymentBuilding', '口座送付先建物名', 'NameChangeAppModel', { maxLength: 50});
		this.addConfig('paymentRoomNum', '口座送付先部屋番号', 'NameChangeAppModel', { maxLength: 5});
		this.addConfig('paymentPostCode', '送付先郵便番号', 'NameChangeAppModel', { maxLength: 7, converter: '郵便番号'});
	}

	/**
	 * ビュー表示通知
	 */
	 public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			this.m_TitleComment = '必要情報をご入力いただき、内容に問題がなければ「確認」をクリックしてください。'
			this.m_EntryInfo.birthdayStr = this.m_DateConverter_JPConverter.convertView(this.m_EntryInfo.nameChangeInfo.birthday);
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			if(this.m_EntryInfo.nameChangePayment.changePaymentFlg) {
				this.setViewMode('新しい支払方法', '表示');
				if (!this.m_EntryInfo.nameChangePayment.creditFlg) {
					this.setViewMode('送付先選択', '表示');
					if(!this.m_EntryInfo.nameChangePayment.contractAddressFlg) {
						this.setViewMode('送付先住所', '表示');
					}
				}
			}
			this.refreshView();
		});
		
		this.m_ErrorModelList = new Array();
		const model = new ErrorModel();
		model.errorMessage = 'エラーリスト';
		this.m_ErrorModelList.push(model);
		this.setListData('ErrorModel', this.m_ErrorModelList);
		this.refreshView();
}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'changePaymentFlg') {
			if (value) {
				this.setViewMode('新しい支払方法', '表示');
			} else {			
				this.setViewMode('新しい支払方法', '非表示');
				this.setViewMode('送付先選択', '非表示');
				this.setViewMode('送付先住所', '非表示');
				this.m_CreditFlg.value = true;
				this.m_ContractAddressFlg.value = true;
				this.m_paymentPostCode.value = null;
				this.m_PaymentAddress.value = null;
				this.m_PaymentBuilding.value = null;
				this.m_PaymentRoomNum.value = null;
			}
		}
		if (name === 'creditFlg') {
			if (value) {
				this.setViewMode('送付先選択', '非表示');
				this.setViewMode('送付先住所', '非表示');
				this.m_ContractAddressFlg.value = true;
				this.m_paymentPostCode.value = null;
				this.m_PaymentAddress.value = null;
				this.m_PaymentBuilding.value = null;
				this.m_PaymentRoomNum.value = null;
			} else {			
				this.setViewMode('送付先選択', '表示');
			}
		}
		if (name === 'contractAddressFlg') {
			if (value) {
				this.setViewMode('送付先住所', '非表示');
				this.m_paymentPostCode.value = null;
				this.m_PaymentAddress.value = null;
				this.m_PaymentBuilding.value = null;
				this.m_PaymentRoomNum.value = null;
			} else {			
				this.setViewMode('送付先住所', '表示');
			}
		}
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		this.refreshModel();
		if (actionName === 'next') {
			this.m_ErrorModelList = new Array();
			if (this.m_ChangePaymentFlg.value && !this.m_CreditFlg.value && !this.m_ContractAddressFlg.value) {
				// 新名義人パターン
				//郵便番号
				if (this.m_paymentPostCode.value) {
					if(!this.m_NumberValidator.checkValidValue(this.m_paymentPostCode.value)) {
						const model = new ErrorModel();
						model.errorMessage = '郵便番号は半角数字で入力してください';
						this.m_ErrorModelList.push(model);
					}
					else if (this.m_paymentPostCode.value.length < 7) {
						const model = new ErrorModel();
						model.errorMessage = '郵便番号は7桁で入力してください';
						this.m_ErrorModelList.push(model);
					}
				}
				else {
					const model = new ErrorModel();
					model.errorMessage = '郵便番号を入力してください';
					this.m_ErrorModelList.push(model);
				}
				//住所
				if (this.m_PaymentAddress.value) {
					if(!this.m_ZenkakuValidator.checkValidValue(this.m_PaymentAddress.value)) {
						const model = new ErrorModel();
						model.errorMessage = '住所は全角で入力してください';
						this.m_ErrorModelList.push(model);
					}
				} else {
					const model = new ErrorModel();
					model.errorMessage = '住所を入力してください';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_PaymentBuilding.value && !this.m_ZenkakuValidator.checkValidValue(this.m_PaymentBuilding.value)) {
					const model = new ErrorModel();
					model.errorMessage = '建物名は全角で入力してください';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_ErrorModelList.length !== 0) {
				this.setViewMode('オプション','エラー')
				this.setListData('ErrorModel', this.m_ErrorModelList);
				validError.addError('エラー');		
			}
			this.refreshListView('ErrorModel');
		}
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------

	/**
	 * 入力情報リセット
	 */
	 private resetInfo(): void {
		this.m_EntryInfo.nameChangePayment.changePaymentFlg = false;
		this.m_EntryInfo.nameChangePayment.creditFlg = true;
		this.m_EntryInfo.nameChangePayment.contractAddressFlg = true;
		this.m_EntryInfo.paymentAddress = null;
		this.m_EntryInfo.paymentBuilding = null;
		this.m_EntryInfo.paymentRoomNum = null;
	}

	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_EntryInfo.nameChangePayment.creditFlg = this.m_CreditFlg.value;
		this.m_EntryInfo.nameChangePayment.contractAddressFlg = this.m_ContractAddressFlg.value;
		this.m_EntryInfo.paymentPostCode = this.m_paymentPostCode.value;
		this.m_EntryInfo.paymentAddress = this.m_PaymentAddress.value;
		this.m_EntryInfo.paymentBuilding = this.m_PaymentBuilding.value;
		this.m_EntryInfo.paymentRoomNum = this.m_PaymentRoomNum.value;
		if (this.m_EntryInfo.nameChangePayment.changePaymentFlg) {
			if (this.m_EntryInfo.nameChangePayment.contractAddressFlg) {
				this.m_EntryInfo.nameChangePayment.deliveryAddress = this.m_Customer.address[0].addressLine1 + this.m_Customer.address[0].addressLine2 
					+ '　' + this.m_Customer.address[0].addressLine3 + '　' + this.m_Customer.address[0].roomNumber;
			}
			else
				this.m_EntryInfo.nameChangePayment.deliveryAddress =  '〒' + this.m_EntryInfo.paymentPostCode.substring(0,3) + '-' + this.m_EntryInfo.paymentPostCode.substring(3,7) + '　' + this.m_EntryInfo.paymentAddress + '　' + this.m_EntryInfo.paymentBuilding + '　' + this.m_EntryInfo.paymentRoomNum;
		}
		// 問合せ登録
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		const nameChangeNewApiModel = new NameChangeNewApiModel();
		this.m_CommonService.registInfo(this, nameChangeNewApiModel.createInquiry(this, this.m_MypageWizardMngr.getCache(), req), (result) => {
			if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS)
				this.m_MypageWizardMngr.goNext(this);
			 else
				this.pushPath('/error');
		});
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.resetInfo();
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
