import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { RelocateBaseModel } from '../../../models/mypage/relocate/RelocateBaseModel';
import { RelocateServiceListModel } from '../../../models/mypage/relocate/RelocateServiceListModel';
import { GetConstructionCostRequest } from '../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * 移設申込確認画面
 */
export class RelocateComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('RelocateComfirmView'); }
	// --------------------------------------------------------------------------
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('RelocateBaseModel')
	private m_RelocateBaseModel: RelocateBaseModel	= null;	// 移設基本モデル
	@WprListData('TvListModel')
	private m_TvListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprListData('NetListModel')
	private m_NetListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprListData('PhoneListModel')
	private m_PhoneListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('no', '台目', 'TvListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'TvListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'TvListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'TvListModel', { bindName: 'location' });
		this.addListConfig('comment', 'フリー入力', 'TvListModel', { bindName: 'comment' });
		this.addConfig('desiredDate1', '第一希望', 'RelocateBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'RelocateBaseModel', { disabled: true });
		this.addConfig('desiredDate2', '第二希望', 'RelocateBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'RelocateBaseModel', { disabled: true });
		this.addConfig('desiredDate3', '第三希望', 'RelocateBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'RelocateBaseModel', { disabled: true });
		this.addConfig('amount', '工事費', 'RelocateBaseModel', { converter: '3桁カンマ' });
		this.addListConfig('no', '台目', 'NetListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'NetListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'NetListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'NetListModel', { bindName: 'location' });
		this.addListConfig('comment', 'フリー入力', 'NetListModel', { bindName: 'comment' });
		this.addListConfig('no', '台目', 'PhoneListModel', { bindName: 'no' });
		this.addListConfig('serviceInfo', 'サービス等 ( 表示用 )', 'PhoneListModel', { bindName: 'serviceInfo' });
		this.addListConfig('floor', '階数', 'PhoneListModel', { bindName: 'floor' });
		this.addListConfig('location', '場所', 'PhoneListModel', { bindName: 'location' });
		this.addListConfig('comment', 'フリー入力', 'PhoneListModel', { bindName: 'comment' });
		this.addConfig('beforeContact', '前日連絡先', 'RelocateBaseModel');
		this.addConfig('sameDayContact', '当日連絡先', 'RelocateBaseModel');
		this.addConfig('name', '立ち合い者名', 'RelocateBaseModel', { bindName: 'presentName' });
		this.addConfig('reformDisp', 'リフォーム', 'RelocateBaseModel');
		this.addConfig('tokosapoFee', 'とことんサポート金額', 'RelocateBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_RelocateBaseModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel;
			this.m_TvListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.tvList;
			this.m_NetListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.netList;
			this.m_PhoneListModel = this.m_MypageWizardMngr.getCache().m_RelocateBaseModel.phoneList;
			const building = this.m_MypageWizardMngr.getViewInfo().customer.building[0];
			if (this.m_TvListModel.length > 0) {
				this.setListData('TvListModel', this.m_TvListModel);
				this.setViewMode('テレビ', '表示');
			}
			if (this.m_NetListModel.length > 0) {
				this.setListData('NetListModel', this.m_NetListModel);
				this.setViewMode('ネット', '表示');
			}
			if (this.m_PhoneListModel.length > 0) {
				this.setListData('PhoneListModel', this.m_PhoneListModel);
				this.setViewMode('電話', '表示');
			}
			//リフォーム
			if (this.m_RelocateBaseModel.reformFlg)
				this.m_RelocateBaseModel.reformDisp = 'あり';
			else
				this.m_RelocateBaseModel.reformDisp = 'なし';
			//工事費
			//物件戸建または戸建賃貸かつ、金額非表示
			if (building.buildingTypeCD === '3' || building.buildingTypeCD === '2') {
				this.setViewMode('金額', '非表示')
				this.m_RelocateBaseModel.estimateFlg = true;
			}
			//その他の場合
			else {
				this.setViewMode('金額', '表示');
				const req: GetConstructionCostRequest = new GetConstructionCostRequest();
				req.constructionCategoryCd = '01';
				req.applicationDetailsCd = '01';
				req.temporaryCostCategoryCd = '01';
				req.multipleFlg = false;
				this.m_MypageWizardMngr.getConstructionCost(this, req, null, this.m_RelocateBaseModel.tokotonAddFlg, false, result => {
					if (result) {
						if (result.temporaryCost.length > 0) {
							let amount = 0;
							//とこサポ加入
							if (result.temporaryCost.length === 1)
								amount = result.temporaryCost[0].temporaryCost;
							//とこサポ未加入
							else {
								const cnt = this.m_TvListModel.length + this.m_NetListModel.length + this.m_PhoneListModel.length;
								const firstCost = result.temporaryCost.filter(item => !item.multipleFlg)[0].temporaryCost;
								const secondCost = result.temporaryCost.filter(item => item.multipleFlg)[0].temporaryCost;
								if (cnt !== 0) 
									amount += firstCost + secondCost * (cnt - 1);
							}
							this.m_RelocateBaseModel.amount = this.m_MypageWizardMngr.getTaxFee(this, amount.toString());
						}
						else {
							this.setViewMode('金額', '非表示');
							this.m_RelocateBaseModel.estimateFlg = true;
						}
						this.refreshView();
					}
				});
			}
			//とことんサポート加入するん場合：月額表示
			if (this.m_RelocateBaseModel.tokotonAddFlg) {
				this.setViewMode('とことんサポート', '表示');
				const amount = this.m_MypageWizardMngr.getTaxFee(this, String(this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME)[0].service.payment[0].amount));
				this.m_RelocateBaseModel.tokosapoFee = '月額：' + amount + '円';
			}
			//物件判定
			const customer = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customer.building[0].buildingTypeCD === '1')
				this.setViewMode('物件', '集合');
			else if (customer.building[0].buildingTypeCD === '3' || customer.building[0].buildingTypeCD === '2')
				this.setViewMode('物件', '戸建');
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_RelocateBaseModel.estimateFlg = false;
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}
