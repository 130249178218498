import { MvnoEntryCustomerView } from "../view/mvno/entry/mvno_entry_customer/MvnoEntryCustomerView";

/**
 * ビューの定義
*/
export const VIEW_DEFINE_JSON = [
    {
        viewId: 'S-01-001',
        name: 'S_01_001View',
        title: 'リダイレクトページ',
        virtualPage: '/s001',
        // view:           MvnoEntryCustomerView
    },
    
    // ***************************** 契約内容書面交付 *****************************
    {
        viewId: 'S-01-002',
        name: 'S_01_002',
        title: '電子発行承諾',
        virtualPage: '/s002'
    },
    {
        viewId: 'S_01_003',
        name: 'S_01_003',
        title: '契約内容一覧',
        virtualPage: '/s003'
    },
    {
        viewId: '',
        name: 'S_01_004',
        title: '契約内容確認',
        virtualPage: '/s004'
    },
    {
        viewId: 'S-01-099',
        name: 'S_01_005',
        title: '',
        virtualPage: '/s005'
    },

    // ***************************** MVNO *****************************
    // 申込み ---------------------------------------------------------
    {
        viewId: 'S-01-005',
        name: 'MvnoEntryProcess',
        title: '手続き選択',
        virtualPage: '/mvno_001'
    },
    {
        viewId: 'S-01-006',
        name: 'MvnoEntryPlan1',
        title: 'プラン選択',
        virtualPage: '/mvno_002'
    },
    {
        viewId: 'S-01-007',
        name: 'MvnoEntryPlan2',
        title: 'データ量選択',
        virtualPage: '/mvno_003'
    },
    {
        viewId: 'S-01-008',
        name: 'MvnoEntryPlan3',
        title: 'SIM・端末選択',
        virtualPage: '/mvno_004'
    },
    {
        viewId: 'S-01-009',
        name: 'MvnoEntryCustomer',
        title: 'ご利用者情報入力',
        virtualPage: '/mvno_005'
    },
    {
        viewId: 'S-01-010',
        name: 'MvnoEntryTotalFee',
        title: '合計金額ご確認',
        virtualPage: '/mvno_006'
    },
    {
        viewId: 'S-01-011',
        name: 'MvnoEntryPreConfirm',
        title: '事前確認事項',
        virtualPage: '/mvno_007'
    },
    {
        viewId: 'S-01-012',
        name: 'MvnoEntryIdentification',
        title: '本人確認',
        virtualPage: '/mvno_008'
    },
    {
        viewId: 'S-01-013',
        name: 'MvnoComplete',
        title: '完了',
        virtualPage: '/mvno_009'
    },
    {
        viewId: 'S-01-020',
        name: 'MvnoEntryUserIdentification',
        title: '利用者本人確認',
        virtualPage: '/mvno_010'
    },
    // 容量変更 ---------------------------------------------------------
    {
        viewId: 'S-01-014',
        name: 'MvnoCapacityChange1',
        title: '容量変更',
        virtualPage: '/mvno_change_001'
    },

    {
        viewId: 'S-01-015',
        name: 'MvnoCapacityChange2',
        title: '容量変更確認',
        virtualPage: '/mvno_change_002'
    },
    {
        viewId: 'S-01-016',
        name: 'MvnoCapacityChangePreConfirm',
        title: '事前確認事項',
        virtualPage: '/mvno_change_003'
    },
    {
        viewId: 'S-01-037',
        name: 'MvnoComplete',
        title: '容量変更完了',
        virtualPage: '/mvno_change_004'
    },

    // MNP問い合わせ ---------------------------------------------------------
    {
        viewId: 'S-01-017',
        name: 'MvnoMnpInquiry',
        title: 'MNP問い合わせ',
        virtualPage: '/mvno_mnp_001'
    },
    {
        viewId: 'S-01-018',
        name: 'MvnoMnpInquiryConfirm',
        title: 'MNP問い合わせ確認',
        virtualPage: '/mvno_mnp_002'
    },
    {
        viewId: 'S-01-0137',
        name: 'MvnoComplete',
        title: 'MNP問い合わせ完了',
        virtualPage: '/mvno_mnp_003'
    },

    // データ容量確認・追加申込 ---------------------------------------------------------
    {
        viewId: '',
        name: 'MvnoConfirmCapacity',
        title: 'データ容量確認',
        virtualPage: '/mvno_capacity_001'
    },
    {
        viewId: '',
        name: 'MvnoAddCapacity',
        title: 'データ容量追加申込',
        virtualPage: '/mvno_add_capacity_001'
    },
    {
        viewId: '',
        name: 'MvnoAddCapacityConfirm',
        title: 'データ容量追加申込確認',
        virtualPage: '/mvno_add_capacity_002'
    },
    {
        viewId: '',
        name: 'MvnoComplete',
        title: 'データ容量追加申込完了',
        virtualPage: '/mvno_add_capacity_003'
    },

    // ***************************** アンバサダー *****************************
    // アンバサダー -----------------------------------------------------------
    {
        viewId: 'S-01-017',
        name: 'Ambassador',
        title: 'お友だち紹介',
        virtualPage: '/ambassador_001'
    },
    
    // ***************************** 利用明細 *****************************
    // 利用明細 -----------------------------------------------------------
    {
        viewId: 'S-01-017',
        name: 'MvnoMnpInquiry',
        title: 'MNP問い合わせ',
        virtualPage: '/mvno_mnp_001'
    },
    
    // ***************************** エラー *****************************
    // エラー ---------------------------------------------------------
    {
        viewId: 'S-01-020',
        name: 'UsageDetails_001',
        title: '利用明細',
        virtualPage: '/UsageDetails_001'
    },

    // ***************************** ITSCOM HOME *****************************
    // コース選択（追加申込み） -----------------------------------------------------------
    {
        viewId: 'S-01-021',
        name: 'ItscomHomeSelect1View',
        title: 'iTSCOM Smart 追加申込み',
        virtualPage: '/itscom_home_select1'
    },
    // コース内容の選択（追加申込み） -----------------------------------------------------------
    {
        viewId: 'S-01-022',
        name: 'ItscomHomeSelect2View',
        title: 'iTSCOM Smart 追加申込み',
        virtualPage: '/itscom_home_select2'
    },
    // 申込み内容の確認（追加申込み）-----------------------------------------------------------
    {
        viewId: 'S-01-023',
        name: 'ItscomHomeComfirmView',
        title: 'iTSCOM Smart 追加申込み確認',
        virtualPage: '/itscom_home_confirm'
    },
    // 申込み内容の選択（追加申込み）-----------------------------------------------------------
    {
        viewId: 'S-01-024',
        name: 'CommonAgreeView',
        title: 'iTSCOM Smart 契約約款',
        virtualPage: '/itscom_home_agree'
    },
    // 申込み完了（追加申込み） -----------------------------------------------------------
    {
        viewId: 'S-01-025',
        name: 'CommonCompleteView',
        title: 'iTSCOM Smart 追加申込み完了',
        virtualPage: '/itscom_home_complete'
    },
    // コース選択(オプション変更) --------------------------------------------------
    {
        viewId: 'S-01-026',
        name: 'ItscomHomeReviseSelectView',
        title: 'iTSCOM Smart オプション変更',
        virtualPage: '/itscom_home_revise_select'
    },
    // 申込み内容の確認(オプション変更) --------------------------------------------------
    {
        viewId: 'S-01-027',
        name: 'ItscomHomeReviceComfirmView',
        title: 'iTSCOM Smart オプション変更確認',
        virtualPage: '/itscom_home_revise_confirm'
    },
    // 申込み完了(オプション変更) --------------------------------------------------
    {
        viewId: 'S-01-028',
        name: 'ItscomHomeReviceCompleteView',
        title: 'iTSCOM Smart オプション変更完了',
        virtualPage: '/itscom_home_revise_complete'
    },
    // ***************************** 動画配信サービス *****************************
    // 選択確認（見放題パックプライム） -----------------------------------------------------------
    {
        viewId: 'S-01-029',
        name: 'VideoUnlimitedComfirmView',
        title: '動画配信サービス 見放題パックプライム申込確認',
        virtualPage: '/video_unlimited_comfirm'
    },
    // 契約約款 (見放題パックプライム) -------------------------------------------------------------------------
    {
        viewId: 'S-01-030',
        name: 'CommonAgreeView',
        title: '動画配信サービス 契約約款',
        virtualPage: '/video_unlimited_agree'
    },
    // 完了 (見放題パックプライム) -----------------------------------------------------------
    {
        viewId: 'S-01-031',
        name: 'CommonCompleteView',
        title: '動画配信サービス 見放題パックプライム申込完了',
        virtualPage: '/video_unlimited_complete'
    },
    // 選択確認（IP-STB） -----------------------------------------------------------
    {
        viewId: 'S-01-032',
        name: 'VideoIpstbComfirmView',
        title: '動画配信サービス IP-STB申込確認',
        virtualPage: '/video_ipstb_comfirm'
    },
    // 契約約款（IP-STB） -------------------------------------------------------------------------
    {
        viewId: 'S-01-033',
        name: 'CommonAgreeView',
        title: '動画配信サービス 契約約款',
        virtualPage: '/video_ipstb_agree'
    },
    // 完了 (IP-STB) -----------------------------------------------------------
    {
        viewId: 'S-01-034',
        name: 'CommonCompleteView',
        title: '動画配信サービス IP-STB申込完了',
        virtualPage: '/video_ipstb_complete'
    },
    // 確認（パスワード再発行） -----------------------------------------------------------
    {
        viewId: 'S-01-035',
        name: 'VideoPassComfirmView',
        title: '動画配信サービス パスワード再発行確認',
        virtualPage: '/video_pass_comfirm'
    },
    // 完了（パスワード再発行） -----------------------------------------------------------
    {
        viewId: 'S-01-036',
        name: 'VideoPassCompleteView',
        title: '動画配信サービス パスワード再発行完了',
        virtualPage: '/video_pass_complete'
    },
    // 印刷（パスワード再発行） -----------------------------------------------------------
    {
        viewId: 'S-01-037',
        name: 'VideoPassPrintView',
        title: '動画配信サービス パスワード再発行',
        virtualPage: '/video_pass_print'
    },
    // ***************************** ITSCOM MAGAZINE *****************************
    // 申込確認（ITSCOM マガジン） -----------------------------------------------------------
    {
        viewId: 'S-01-038',
        name: 'MagazineConfirmView',
        title: '番組案内誌変更確認',
        virtualPage: '/magazine_confirm'
    },
    // 解約確認（ITSCOM マガジン） -----------------------------------------------------------
    {
        viewId: 'S-01-039',
        name: 'MagazineCancelConfirmView',
        title: '番組案内誌変更確認',
        virtualPage: '/magazine_cancel_confirm'
    },
    // 申込完了（ITSCOM マガジン） -----------------------------------------------------------
    {
        viewId: 'S-01-040',
        name: 'MagazineApplyCompleteView',
        title: '番組案内誌変更完了',
        virtualPage: '/magazine_apply_complete'
    },
    // 解約完了（ITSCOM マガジン） -----------------------------------------------------------
     {
        viewId: 'S-01-041',
       name: 'MagazineCancelCompleteView',
       title: '番組案内誌変更完了',
       virtualPage: '/magazine_cancel_complete'
    },
    // ***************************** 名義変更 *****************************
    // 名義変更受付の案内 -----------------------------------------------------------
    {
        viewId: 'S-01-042',
        name: 'NameChangeInformationView',
        title: '名義変更',
        virtualPage: '/name_change_information'
    },
    // 名義変更受付フォーム -----------------------------------------------------------
    {
        viewId: 'S-01-043',
        name: 'NameChangeFormView',
        title: '名義変更 受付フォーム',
        virtualPage: '/name_change_form'
    },
    // 名義変更約款確認 -------------------------------------------------------------------------
    {
        viewId: 'S-01-044',
        name: 'CommonAgreeView',
        title: '名義変更 約款確認',
        virtualPage: '/name_change_agree'
    },
    // 名義変更本人確認 -------------------------------------------------------------------------
    {
        viewId: 'S-01-045',
        name: 'NameChangeIdentificationView',
        title: '証明書類 WEB提出',
        virtualPage: '/name_change_identification'
    },
    // 名義変更申込み内容確認 -------------------------------------------------------------------------
    {
        viewId: 'S-01-046',
        name: 'NameChangeConfirmView',
        title: '名義変更 申込み内容確認',
        virtualPage: '/name_change_confirm'
    },
    // 名義変更完了 -----------------------------------------------------------
    {
        viewId: 'S-01-047',
        name: 'NameChangeCompleteView',
        title: '名義変更 完了',
        virtualPage: '/name_change_complete'
    },
    // 追加申込（とことんサポート） -----------------------------------------------------------
    {
        viewId: 'S-01-048',
        name: 'TokotonSupportSelectView',
        title: 'とことんサポート追加申込み 入力',
        virtualPage: '/tokoton_support_select'
    },
    // 確認申込（とことんサポート） -----------------------------------------------------------
    {
        viewId: 'S-01-049',
        name: 'TokotonSupportConfirmView',
        title: 'とことんサポート追加申込み 確認',
        virtualPage: '/tokoton_support_confirm'
    },
    // 契約約款（とことんサポート） -----------------------------------------------------------
    {
        viewId: 'S-01-050',
        name: 'CommonAgreeView',
        title: 'とことんサポート 契約約款',
        virtualPage: '/tokoton_support_useagree'
    },
    // 申込み完了（とことんサポート） -----------------------------------------------------------
    {
        viewId: 'S-01-051',
        name: 'CommonCompleteView',
        title: 'とことんサポート追加申込み 完了',
        virtualPage: '/tokoton_support_complete'
    },
    // ***************************** かっとびモバイル *****************************
    // 解約申込み（かっとびモバイル） -----------------------------------------------------------
    {
        viewId: 'S-01-052',
        name: 'MobileConfirmView',
        title: 'かっとびモバイル 確認解約申込み',
        virtualPage: '/mobile_confirm'
    },
    // 解約完了（かっとびモバイル） -----------------------------------------------------------
    {
        viewId: 'S-01-053',
        name: 'MobileCompleteView',
        title: 'かっとびモバイル 解約申込み完了',
        virtualPage: '/mobile_complete'
    },
    // ***************************** テレビ *****************************
    // コース選択（追加） -----------------------------------------------------------
    {
        viewId: 'S-01-054',
        name: 'AddTvSelect1View',
        title: 'iTSCOM TV サービス追加（変更）申込み',
        virtualPage: '/add_tv_select1'
    },
    // コース内容の選択（追加） -----------------------------------------------------------
    {
        viewId: 'S-01-055',
        name: 'AddTvSelect2View',
        title: 'iTSCOM TV サービス追加（変更）申込み',
        virtualPage: '/add_tv_select2'
    },
    // 申込み内容の確認（追加） -----------------------------------------------------------
    {
        viewId: 'S-01-056',
        name: 'AddTvComfirmView',
        title: 'iTSCOM TV サービス追加（変更）申込み内容の確認',
        virtualPage: '/add_tv_comfirm'
    },
    // 契約約款（追加） -----------------------------------------------------------
    {
        viewId: 'S-01-057',
        name: 'CommonAgreeView',
        title: 'iTSCOM TV 契約約款',
        virtualPage: '/add_tv_agree'
    },
    // 申込み完了（追加） -----------------------------------------------------------
    {
        viewId: 'S-01-058',
        name: 'CommonCompleteView',
        title: 'iTSCOM TV 追加申込み完了',
        virtualPage: '/add_tv_complete'
    },
    // ***************************** iTSCOM NET *****************************
    // 変更入力（アクセスアカウント） -----------------------------------------------------------
    {
        viewId: 'S-01-059',
        name: 'AddAccesspassSelectView',
        title: 'iTSCOM NET アクセスアカウント パスワード 変更',
        virtualPage: '/add_accesspass_select'
    },
    // 変更確認（アクセスアカウント） -----------------------------------------------------------
    {
        viewId: 'S-01-060',
        name: 'AddAccesspassConfirmView',
        title: 'iTSCOM NET アクセスアカウント パスワード 変更確認',
        virtualPage: '/add_accesspass_confirm'
    },
    // 変更完了（アクセスアカウント） -----------------------------------------------------------
    {
        viewId: 'S-01-061',
        name: 'AddAccesspassCompleteView',
        title: 'iTSCOM NET アクセスアカウント パスワード 変更完了',
        virtualPage: '/add_accesspass_complete'
    },
    // 印刷画面（アクセスアカウント） -----------------------------------------------------------
    {
        viewId: 'S-01-062',
        name: 'AccesspassPrintView',
        title: 'iTSCOM NET アクセスアカウント パスワード',
        virtualPage: '/accesspass_print'
    },
    
    // アクセスアカウントパスワード発行確認 ---------------------------------------------------------
    {
        viewId: 'S-01-063',
        name: 'ReiAccesspassConfirmView',
        title: 'iTSCOM NET アクセスアカウント パスワード 発行確認',
        virtualPage: '/rei_accesspass_confirm'
    },
    // アクセスアカウントパスワード発行完了 ---------------------------------------------------------
    {
        viewId: 'S-01-064',
        name: 'ReiAccesspassCompleteView',
        title: 'iTSCOM NET アクセスアカウント パスワード 発行完了',
        virtualPage: '/rei_accesspass_complete'
    },
    // アクセスアカウントパスワード発行完了 ---------------------------------------------------------
    {
        viewId: 'S-01-065',
        name: 'ReiAccesspassPrintView',
        title: 'iTSCOM NET アクセスアカウント パスワード',
        virtualPage: '/rei_accesspass_print'
    },
    // iTSCOM net 追加申込み コース選択 -------------------------------------------------------------
    {
        viewId: 'S-01-066',
        name: 'AddNetSelectView',
        title: 'iTSCOM NET サービス追加（変更）申込み',
        virtualPage: '/add_net_select'
    },
    // iTSCOM net 追加申込み コース内容選択 -------------------------------------------------------------
    {
        viewId: 'S-01-067',
        name: 'AddNetSettingView',
        title: 'iTSCOM NET サービス追加（変更）申込み',
        virtualPage: '/add_net_setting'
    },

    // iTSCOM net 追加申込み その他コース選択 -------------------------------------------------------------
    {
        viewId: 'S-01-068',
        name: 'AddOthernetSelectView',
        title: 'iTSCOM NET サービス追加（変更）申込み内容の確認',
        virtualPage: '/add_othernet_select'
    },
    // iTSCOM net 追加申込み 確認 -------------------------------------------------------------
    {
        viewId: 'S-01-069',
        name: 'AddNetConfirmView',
        title: 'iTSCOM NET サービス追加（変更）申込み内容の確認',
        virtualPage: '/add_net_confirm'
    },
    // iTSCOM net 追加申込み 契約約款 -------------------------------------------------------------
    {
        viewId: 'S-01-070',
        name: 'CommonAgreeView',
        title: 'iTSCOM NET 契約約款',
        virtualPage: '/service_net_useagree'
    },
    // iTSCOM net 追加申込み 完了 -------------------------------------------------------------
    {
        viewId: 'S-01-071',
        name: 'CommonCompleteView',
        title: 'iTSCOM .net 追加申込み完了',
        virtualPage: '/add_net_complete'
    },
     // IPアドレス種別選択オプション変更 申込み内容の確認 -------------------------------------------------------------
     {
        viewId: 'S-01-072',
        name: 'ModIpopConfirmView',
        title: 'iTSCOM NET IPアドレス種別選択オプション 変更確認',
        virtualPage: '/mod_ipop_confirm'
    },
     // IPアドレス種別選択オプション変更 変更申込み完了 -------------------------------------------------------------
     {
        viewId: 'S-01-073',
        name: 'ModIpopFinishView',
        title: 'iTSCOM NET IPアドレス種別選択オプション 変更完了',
        virtualPage: '/mod_ipop_finish'
    },
     // iTSCOM net 変更申込み コース選択 -------------------------------------------------------------
     {
        viewId: 'S-01-074',
        name: 'ModNetSelectView',
        title: 'iTSCOM NET サービス追加（変更）申込み',
        virtualPage: '/mod_net_select'
    },
    // iTSCOM net 変更申込み コース内容選択 -------------------------------------------------------------
    {
        viewId: 'S-01-075',
        name: 'ModNetSettingView',
        title: 'iTSCOM NET サービス追加（変更）申込み',
        virtualPage: '/mod_net_setting'
    },
     // iTSCOM net 変更申込み その他コース選択 -------------------------------------------------------
     {
        viewId: 'S-01-076',
        name: 'ModOthernetSelectView',
        title: 'iTSCOM NET サービス追加（変更）申込み',
        virtualPage: '/mod_othernet_select'
    },
    // iTSCOM net 変更申込み その他コース確認 -------------------------------------------------------------
    {
        viewId: 'S-01-077',
        name: 'ModOthernetConfirmView',
        title: 'iTSCOM NET サービス追加（変更）申込み内容の確認',
        virtualPage: '/mod_othernet_confirm'
    },
     // iTSCOM net 変更申込み 確認 -------------------------------------------------------------
     {
        viewId: 'S-01-111',
        name: 'ModNetConfirmView',
        title: 'iTSCOM NET サービス追加（変更）申込み内容の確認',
        virtualPage: '/mod_net_confirm'
    },
     // iTSCOM net 変更申込み 完了 -------------------------------------------------------------
     {
        viewId: 'S-01-078',
        name: 'CommonCompleteView',
        title: 'iTSCOM .net 変更申込み完了',
        virtualPage: '/mod_net_complete'
    },
    // ***************************** iTSCOM PHONE *****************************
    // 申込み内容の選択 ---------------------------------------------------------
    {
        viewId: 'S-01-079',
        name: 'AddPhoneSelectView',
        title: 'iTSCOM PHONE サービス追加（変更）申込み',
        virtualPage: '/add_phone_select'
    },
    // 申込み内容の確認 ---------------------------------------------------------
    {
        viewId: 'S-01-080',
        name: 'AddPhoneConfirmView',
        title: 'iTSCOM PHONE サービス追加（変更）申込み',
        virtualPage: '/add_phone_confirm'
    },
    // 契約約款 ---------------------------------------------------------
    {
        viewId: 'S-01-081',
        name: 'CommonAgreeView',
        title: 'iTSCOM PHONE 契約約款',
        virtualPage: '/add_phone_useagree'
    },
    // 申込み完了 ---------------------------------------------------------
    {
        viewId: 'S-01-082',
        name: 'CommonCompleteView',
        title: 'iTSCOM PHONE 追加申込み完了',
        virtualPage: '/add_phone_complete'
    },
    // ***************************** NHK *****************************
    // NHK 申込み --------------------------------------------------------
    {
        viewId: 'S-01-083',
        name: 'AddNhkSelectView',
        title: 'NHK団体一括支払い申込み',
        virtualPage: '/add_nhk_select'
    },
    // NHK 申込み確認 --------------------------------------------------------
    {
        viewId: 'S-01-084',
        name: 'AddNhkConfirmView',
        title: 'NHK団体一括支払い申込み確認',
        virtualPage: '/add_nhk_confirm'
    },
    // NHK 申込み完了 --------------------------------------------------------
    {
        viewId: 'S-01-085',
        name: 'CommonCompleteView',
        title: 'NHK団体一括支払い申込み確認完了',
        virtualPage: '/add_nhk_complete'
    },
    // NHK 解約確認 --------------------------------------------------------
    {
        viewId: 'S-01-086',
        name: 'ModNhkConfirmView',
        title: 'NHK団体一括支払い解約確認',
        virtualPage: '/mod_nhk_confirm'
    },
    // NHK 解約完了 --------------------------------------------------------
    {
        viewId: 'S-01-087',
        name: 'CommonCompleteView',
        title: 'NHK団体一括支払い解約完了',
        virtualPage: '/mod_nhk_complete'
    },
    // ***************************** テレビ *****************************
    // コース選択（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-088',
        name: 'ModTvSelect1View',
        title: 'iTSCOM TV サービス変更申込み',
        virtualPage: '/mod_tv_select1'
    },
    // コース内容の選択（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-089',
        name: 'ModTvSelect2View',
        title: 'iTSCOM TV サービス変更申込み',
        virtualPage: '/mod_tv_select2'
    },
    // 申込み内容の確認（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-090',
        name: 'ModTvComfirmView',
        title: 'iTSCOM TV サービス変更申込み内容の確認',
        virtualPage: '/mod_tv_comfirm'
    },
    // 契約約款（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-091',
        name: 'CommonAgreeView',
        title: 'iTSCOM TV 契約約款',
        virtualPage: '/mod_tv_agree'
    },
    // 申込み完了（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-092',
        name: 'CommonCompleteView',
        title: 'iTSCOM TV 変更申込み完了',
        virtualPage: '/mod_tv_complete'
    },
    // 機器変更選択（機器変更） -----------------------------------------------------------
    {
        viewId: 'S-01-093',
        name: 'ModTvequipSelectView',
        title: 'iTSCOM TV 機器変更',
        virtualPage: '/mod_tvequip_select'
    },
    // 契約約款（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-094',
        name: 'ModTvequipConfirmView',
        title: 'iTSCOM TV 機器変更確認',
        virtualPage: '/mod_tvequip_confirm'
    },
    // 申込み完了（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-095',
        name: 'CommonCompleteView',
        title: 'iTSCOM TV 機器変更完了',
        virtualPage: '/mod_tvequip_complete'
    },
    // オプション変更選択（TVオプション） -----------------------------------------------------------
    {
        viewId: 'S-01-096',
        name: 'AddTvopSelectView',
        title: 'iTSCOM TV テレビオプション変更',
        virtualPage: '/add_tvop_select'
    },
    // 内容確認（TVオプション申込） -----------------------------------------------------------
    {
        viewId: 'S-01-097',
        name: 'AddTvopConfirmView',
        title: 'iTSCOM TV テレビオプション変更確認',
        virtualPage: '/add_tvop_confirm'
    },
    // 申込み完了（TVオプション申込） -----------------------------------------------------------
    {
        viewId: 'S-01-098',
        name: 'TvopCompleteView',
        title: 'iTSCOM TV テレビオプション変更完了',
        virtualPage: '/add_tvop_complete'
    },
    // 内容確認（TVオプション仮申込） -----------------------------------------------------------
    {
        viewId: 'S-01-099',
        name: 'AddTvopprovConfirmView',
        title: 'iTSCOM TV テレビオプション変更確認',
        virtualPage: '/add_tvopprov_confirm'
    },
    // 申込み完了（TVオプション仮申込） -----------------------------------------------------------
    {
        viewId: 'S-01-100',
        name: 'TvopCompleteView',
        title: 'お申込みありがとうございます！',
        virtualPage: '/add_tvopprov_complete'
    },
    // 内容確認（TVオプション解約） -----------------------------------------------------------
    {
        viewId: 'S-01-101',
        name: 'ModTvopConfirmView',
        title: 'iTSCOM TV テレビオプション変更確認',
        virtualPage: '/mod_tvop_confirm'
    },
    // 申込み完了（TVオプション解約） -----------------------------------------------------------
    {
        viewId: 'S-01-102',
        name: 'TvopCompleteView',
        title: '解約手続きが完了しました。',
        virtualPage: '/mod_tvop_complete'
    }
    ,
    // 申込み完了（変更） -----------------------------------------------------------
    {
        viewId: 'S-01-097',
        name: 'LineLiffView',
        title: 'LINEとiTSCOMマイページの連携',
        virtualPage: '/line_liff'
    },
    {
        viewId: 'S-01-098',
        name: 'LineAgreeView',
        title: 'LINEとiTSCOMマイページの連携',
        virtualPage: '/line_agree'
    },
    // 申込み内容の選択（メッシュWifi） -----------------------------------------------------------
    {
        viewId: 'S-01-99',
        name: 'AddMeshWifiSelectView',
        title: 'メッシュWi-Fi サービス追加（変更）申込み',
        virtualPage: '/add_meshWifi_select'
    },
     // 申込み内容の確認（メッシュWifi） -----------------------------------------------------------
     {
        viewId: 'S-01-100',
        name: 'AddMeshWifiConfirmView',
        title: 'メッシュWi-Fi サービス追加（変更）申込みの確認',
        virtualPage: '/add_meshWifi_confirm'
    },
     // 契約約款（メッシュWifi） -----------------------------------------------------------
     {
        viewId: 'S-01-101',
        name: 'CommonAgreeView',
        title: 'メッシュWi-Fi 契約約款',
        virtualPage: '/add_meshWifi_agree'
    },
     // 申込み完了（メッシュWifi） -----------------------------------------------------------
     {
        viewId: 'S-01-102',
        name: 'CommonCompleteView',
        title: 'メッシュWi-Fiサービス追加（変更）申込み',
        virtualPage: '/add_meshWifi_complete'
    },
    // 移設機器の選択（移設申込） -----------------------------------------------------------
    {
        viewId: 'S-01-103',
        name: 'RelocateSelect1View',
        title: '機器移動のお申込み',
        virtualPage: '/relocate_select1'
    },
     // 移設希望場所の選択（移設申込） -----------------------------------------------------------
     {
        viewId: 'S-01-104',
        name: 'RelocateSelect2View',
        title: '(機器)移動希望場所の選択',
        virtualPage: '/relocate_select2'
    },
     // 申込内容の確認（移設申込） -----------------------------------------------------------
     {
        viewId: 'S-01-105',
        name: 'RelocateComfirmView',
        title: 'お申込み内容の確認',
        virtualPage: '/relocate_comfirm'
    },
     // 移設申込み完了（移設申込） -----------------------------------------------------------
     {
        viewId: 'S-01-106',
        name: 'CommonCompleteView',
        title: '機器移動お申込み完了',
        virtualPage: '/relocate_complete'
    }, 
    // NHK 変更申込み --------------------------------------------------------
    {
        viewId: 'S-01-107',
        name: 'NhkEditView',
        title: 'NHK団体一括支払い申込み',
        virtualPage: '/nhk_edit'
    },
    // 移設契約約款 -----------------------------------------------------------
    {
       viewId: 'S-01-108',
       name: 'CommonAgreeView',
       title: 'とことんサポート 契約約款',
       virtualPage: '/relocate_agree'
   },
  // iTSCOM net 追加申込み その他コース選択 確認 -------------------------------------------------------------
  {
    viewId: 'S-01-109',
    name: 'AddOthernetConfirmView',
    title: 'iTSCOM NET サービス追加（変更）申込み内容の確認',
    virtualPage: '/add_othernet_confirm'
    },
    // お問い合わせ -----------------------------------------------------------
    {
    viewId: 'S-01-110',
    name: 'CustomerSupportView',
    title: 'お問い合わせ',
    virtualPage: '/customer_support'
    },
    // 工事予定日一覧 -----------------------------------------------------------
    {
        viewId: 'S-01-111',
        name: 'ConstrListView',
        title: '工事予定日一覧',
        virtualPage: '/constr_list'
    },
    // 工事注意事項 -----------------------------------------------------------
    {
        viewId: 'S-01-112',
        name: 'ContsrNotesView',
        title: '工事予定設定についての注意事項説明',
        virtualPage: '/contsr_notes'
    },
     // 工事希望予定日の検索 -----------------------------------------------------------
     {
        viewId: 'S-01-113',
        name: 'ConstrSearchView',
        title: '希望予定日の検索',
        virtualPage: '/constr_search'
    },
     // 工事希望予定日の確認 -----------------------------------------------------------
     {
        viewId: 'S-01-114',
        name: 'ConstrConfirmView',
        title: '希望予定日の確認',
        virtualPage: '/constr_confirm'
    },
     // 工事申込み完了 -----------------------------------------------------------
     {
        viewId: 'S-01-115',
        name: 'CommonCompleteView',
        title: '工事予定日が設定されました！',
        virtualPage: '/constr_complete'
    }, 
    // 着信転送申込み -----------------------------------------------------------
    {
        viewId: 'S-01-116',
        name: 'CallForwardingSelectView',
        title: '着信転送 申込み',
        virtualPage: '/call_forwarding_select'
    }, 
    // 着信転送確認 -----------------------------------------------------------
    {
        viewId: 'S-01-117',
        name: 'CallForwardingConfirmView',
        title: 'WEB本人確認',
        virtualPage: '/call_forwarding_confirm'
    }, 
    // 着信転送完了 -----------------------------------------------------------
    {
        viewId: 'S-01-118',
        name: 'CallForwardingCompleteView',
        title: 'iTSCOM PHONE 着信転送追加申込み完了',
        virtualPage: '/call_forwarding_complete'
    }, 
    // 工事希望予定日の変更 -----------------------------------------------------------
    {
       viewId: 'S-01-119',
       name: 'ConstrChangeView',
       title: '工事予定日変更',
       virtualPage: '/constr_change'
   },
   // 工事希望予定日のキャンセル -----------------------------------------------------------
   {
      viewId: 'S-01-120',
      name: 'ConstrCancelView',
      title: 'お申込みキャンセル',
      virtualPage: '/contsr_cancel'
  },
   // 工事申込み完了 -----------------------------------------------------------
   {
      viewId: 'S-01-121',
      name: 'CommonCompleteView',
      title: '予定キャンセル',
      virtualPage: '/constr_cancel_complete'
  }, 
    // 工事希望予定日の確認 -----------------------------------------------------------
  {
     viewId: 'S-01-122',
     name: 'ConstrLogView',
     title: '過去工事履歴',
     virtualPage: '/contsr_log'
  },
  // 名義変更新名義人受付 -----------------------------------------------------------
  {
     viewId: 'S-01-123',
     name: 'NameChangeNewInformationView',
     title: '新名義人案内ページ',
     virtualPage: '/name_change_new_information',
     noMenuFlg:'1'
  },
  {
     viewId: 'S-01-124',
     name: 'NameChangeNewFormView',
     title: '新名義人受付フォーム',
     virtualPage: '/name_change_new_form',
    //  noMenuFlg:'1'
  },
  {
     viewId: 'S-01-125',
     name: 'NameChangeNewIdentificationView',
     title: '新名義人本人確認',
     virtualPage: '/name_change_new_identification',
    //  noMenuFlg:'1'
  },
  {
     viewId: 'S-01-126',
     name: 'NameChangeNewConfirmView',
     title: '新名義人申込み内容確認',
     virtualPage: '/name_change_new_confirm'
  },
  {
     viewId: 'S-01-127',
     name: 'NameChangeNewCompleteView',
     title: '新名義人受付完了',
     virtualPage: '/name_change_new_complete',
  },
  // ケーブルプラス電話の本人確認 -----------------------------------------------------------
  {
     viewId: 'S-01-128',
     name: 'AddPhoneIdentificationView',
     title: 'WEB本人確認',
     virtualPage: '/add_phone_Identification'
  },  
  // ネット追加 かっとび光完了 -----------------------------------------------------------
  {
    viewId: 'S-01-129',
    name: 'CommonCompleteView',
    title: 'iTSCOM .net 追加申込み完了',
    virtualPage: '/add_kattobi_complete'
  },  
    // ネット変更 かっとび光完了 -----------------------------------------------------------
    {
      viewId: 'S-01-130',
      name: 'CommonCompleteView',
      title: 'iTSCOM .net 変更申込み完了',
      virtualPage: '/mod_kattobi_complete'
    },  
    // 予定変更 完了-----------------------------------------------------------
    {
      viewId: 'S-01-131',
      name: 'CommonCompleteView',
      title: '予定変更',
      virtualPage: '/constr_change_complete'
    },  
    {
        viewId: 'S-01-001',
        name: 'S_01_001View',
        title: 'リダイレクトページ',
        virtualPage: '/',
    },  
    {
        viewId: 'S-01-0132',
        name: 'UsageDetails_001View',
        title: '利用明細',
        virtualPage: '/usage_details_001',
    },  
    {
        viewId: 'S-01-0133',
        name: 'NameChangeErrorView',
        title: '名義変更受付エラー',
        virtualPage: '/nameChangeError',
    },  
    {
        viewId: 'S-01-0134',
        name: 'MypageErrorView',
        title: 'エラー',
        virtualPage: '/error',
    },  
    {
        viewId: 'S-01-0135',
        name: 'MypageServiceErrorView',
        title: 'サービスエラー',
        virtualPage: '/service_error',
    },
    // NHK変更 完了-----------------------------------------------------------
    {
        viewId: 'S-01-0136',
        name: 'CommonCompleteView',
        title: 'NHK団体一括申込み完了',
        virtualPage: '/edit_nhk_complete',
    },
    // 端末登録 -----------------------------------------------------------
    {
        viewId: 'S-01-0137',
        name: 'TerminalRegistView',
        title: '機器登録確認',
        virtualPage: '/terminal_regist',
    },
    // 端末登録 完了-----------------------------------------------------------
    {
        viewId: 'S-01-0138',
        name: 'CommonCompleteView',
        title: '機器登録完了',
        virtualPage: '/terminal_regist_complete',
    },
    // イッツコムラーニング コース選択 -----------------------------------------------------------
    {
        viewId: 'S-01-0139',
        name: 'AddLearningSelectView',
        title: 'イッツコムラーニング 申込み',
        virtualPage: '/add_learning_select',
    },
    // イッツコムラーニング 確認 -----------------------------------------------------------
    {
        viewId: 'S-01-0140',
        name: 'AddLearningConfirmView',
        title: 'イッツコムラーニング 確認',
        virtualPage: '/add_learning_comfirm',
    },
    // イッツコムラーニング 契約約款 -----------------------------------------------------------
    {
        viewId: 'S-01-0141',
        name: 'AddLearningAgreeView',
        title: 'イッツコムラーニング 契約約款',
        virtualPage: '/add_learning_agree',
    },
    // イッツコムラーニング 完了 -----------------------------------------------------------
    {
        viewId: 'S-01-0142',
        name: 'CommonAgreeView',
        title: 'イッツコムラーニング 申込み完了',
        virtualPage: '/add_learning_complete',
    },
     // イッツコムラーニング 解約 -----------------------------------------------------------
     {
        viewId: 'S-01-0143',
        name: 'LearningCancelEntryView',
        title: 'イッツコムラーニング 解約',
        virtualPage: '/learning_cancel_entry',
    },
    // イッツコムラーニング 解約確認 -----------------------------------------------------------
    {
        viewId: 'S-01-0144',
        name: 'LearningCancelConfirmView',
        title: 'イッツコムラーニング解約 確認',
        virtualPage: '/learning_cancel_comfirm',
    },
    // イッツコムラーニング 解約完了 -----------------------------------------------------------
    {
        viewId: 'S-01-0145',
        name: 'LearningCancelCompleteView',
        title: 'イッツコムラーニング 解約申込み完了',
        virtualPage: '/learning_cancel_complete',
    }
]