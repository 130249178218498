import { WizardModel } from '../../models/WizardModel';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { AddPhoneConfirmView } from '../Phone/add_phone_confirm/AddPhoneConfirmView';
import { AddPhoneSelectView } from '../Phone/add_phone_select/AddPhoneSelectView';
import { PhoneInfoModel } from '../Phone/add_phone_select/models/PhoneInfoModel';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { AddPhoneIdentification } from '../Phone/add_phone_identification/AddPhoneIdentification';
import { AddPhoneIdentificationView } from '../Phone/add_phone_identification/AddPhoneIdentificationView';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';


/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class ItscomPhoneNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_CallForwardingFlow: WizardModel[]			= null;
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddPhoneSelectView().name;

		// 通常パターン
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の選択', viewName: this.m_StartViewName, path: 'add_phone_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み内容の確認', viewName: new AddPhoneConfirmView().name, path: 'add_phone_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '契約約款', viewName: new CommonAgreeView().name, path: 'add_phone_useagree'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_phone_complete'});

		// 着信転送申込み
		this.m_CallForwardingFlow = new Array();
		this.m_CallForwardingFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_CallForwardingFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_CallForwardingFlow.push(this.m_DefaultWizardFlow[2]);
		this.m_CallForwardingFlow.push(this.m_DefaultWizardFlow[3]);
		this.m_CallForwardingFlow.push({stepNum: 4, title: 'WEB本人確認', viewName: new AddPhoneIdentificationView().name, path: 'add_phone_Identification'});
		this.m_CallForwardingFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_phone_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: ItscomPhoneAddWizardModel = mngr.getCache();
		if (cacheData.m_PhoneInfo.callForwardingFlg)
			return this.m_CallForwardingFlow;
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.masterFlg = true;
		req.paymentFlg = true;
		req.contractFlg = true;
		req.ownerFlg = true;
		req.buildingFlg = true;
		req.serviceLineFlg = true;
		req.commonList = new Array();
		req.commonList.push(new MCommon('お得パックの取得', 'otoku', 'plan_cd'));
		req.commonList.push(new MCommon('物件プランの取得','building_plan'));
		req.commonList.push(new MCommon('IHオーナーサービスの取得', 'IH', 'OWNER_SCD'));
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE2));	// ItscomPhone_サービス2
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3));	// ItscomPhone_サービス3
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '1'));	// ItscomPhone_レンタルサービス
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMPHONE_SERVICE1));	// ItscomPhone_サービス1
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME));	// ItscomPhone_お得パックサービス
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		// オプション
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PHONE_OPTION));
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.PHONE_CABLEPLUS));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		// とことんサポート
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		// 戸建賃貸FD対象外物件プラン
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.RENTALHOUSE_NONFD_OWNERPLAN, null));
		return req;	
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		let cacheData: ItscomPhoneAddWizardModel = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'Phone_追加';
		});
		// お客様メール
		req.customerMailId = mailconstants.customerMail;
		req.customerId = mngr.getParams().customerId;
		// 業務部門メール
		req.systemMailId = mailconstants.systemMail;
		// 問い合わせ
		req.inquiryId = mailconstants.inquiryMail;
		const customer = mngr.getViewInfo().customer;
		req.systemInfo = new InquiryMailInfo();
		// 申込みサービス
		req.systemInfo.appService = cacheData.m_PhoneInfo.course;
		// 宅内機器の形態
		req.systemInfo.modemForm = cacheData.m_PhoneInfo.equipmentProviding;
		// お得パック状況
		if (cacheData.m_PhoneInfo.otokuFlg) {
			req.systemInfo.otokuStatus = '契約中';
		}
		else if (!cacheData.m_PhoneInfo.otokuFlg) {
			if (cacheData.m_PhoneInfo.yesOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
			else if (cacheData.m_PhoneInfo.noOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
			else
				req.systemInfo.otokuStatus = '';
		}
		req.systemInfo.other = 'ケーブルプラス電話追加申込み';
		// 工事
		req.systemInfo.constractionFlg = true;
		req.systemInfo.desiredDate1 = cacheData.m_PhoneInfo.desiredDate1.replace(/-/g, '/');
		req.systemInfo.desiredDate2 = cacheData.m_PhoneInfo.desiredDate2.replace(/-/g, '/');
		req.systemInfo.desiredDate3 = cacheData.m_PhoneInfo.desiredDate3.replace(/-/g, '/');
		req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_PhoneInfo.selectTime1);
		req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_PhoneInfo.selectTime2);
		req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_PhoneInfo.selectTime3);
		// 希望場所
		if (cacheData.m_PhoneInfo.floor)
			req.systemInfo.location = cacheData.m_PhoneInfo.floor + ' ' + cacheData.m_PhoneInfo.location;
		else
			req.systemInfo.location = cacheData.m_PhoneInfo.location;
		if (cacheData.m_PhoneInfo.location === 'その他')
			req.systemInfo.location += ( ' ' + cacheData.m_PhoneInfo.comment);
		// 工事費
		if (customer.building[0].buildingTypeCD === '3' || customer.building[0].buildingTypeCD === '2')
			req.systemInfo.constructionCost = cacheData.m_PhoneInfo.cost;
		else {
			if (cacheData.m_PhoneInfo.payUnit)
				req.systemInfo.constructionCost = Number(cacheData.m_PhoneInfo.cost).toLocaleString() + cacheData.m_PhoneInfo.payUnit;
			else
				req.systemInfo.constructionCost = cacheData.m_PhoneInfo.cost;
		}
		// セキュリティ会社
		if (cacheData.m_PhoneInfo.securityComment)
			req.systemInfo.securityComment = cacheData.m_PhoneInfo.securityComment;
		else
			req.systemInfo.securityComment = 'なし';
		// リフォーム有無
		if (cacheData.m_PhoneInfo.reformFlg)
			req.systemInfo.reform = 'あり';
		else
			req.systemInfo.reform = 'なし';
		// 連絡先
		req.systemInfo.beforeContact = cacheData.m_PhoneInfo.beforeContact;
		req.systemInfo.sameDayContact = cacheData.m_PhoneInfo.sameDayContact;
		req.systemInfo.witness = cacheData.m_PhoneInfo.name;
		// オプション追加
		const addOptionList = cacheData.m_OptionListModel.filter(row => row.check);
		if (addOptionList && addOptionList.length > 0) {
			req.systemInfo.optionRentalList = new Array();
			for (const addOption of addOptionList)
				req.systemInfo.optionRentalList.push(addOption.optionName);
		}
		// au携帯利用中
		if (cacheData.m_PhoneInfo.auPhoneUsing === '1')
			req.systemInfo.auPhoneNunber = cacheData.m_PhoneInfo.auPhoneNumber;
		else
			req.systemInfo.auPhoneNunber = 'なし';
		// 今ある電話番号を使う
		if (cacheData.m_PhoneInfo.phoneNumerSelect === '2') {
			req.systemInfo.newLandlineNumerFlg = false;
			let carrier: string = null;
			if (cacheData.m_PhoneInfo.lineType === '1')
				carrier = 'NTTアナログ電話';
			else if (cacheData.m_PhoneInfo.lineType === '2')
				carrier = 'ホームプラス電話';
			else if (cacheData.m_PhoneInfo.lineType === '3')
				carrier = 'NTT光';
			else if (cacheData.m_PhoneInfo.lineType === '4')
				carrier = 'auひかり';
			else if (cacheData.m_PhoneInfo.lineType === '5')
				carrier = 'NTTISDN';
			else if (cacheData.m_PhoneInfo.lineType === '6')
				carrier = 'ソフトバンク';
			if (cacheData.m_PhoneInfo.lineType === '7')
				carrier = 'その他：' + cacheData.m_PhoneInfo.otherLineComment;
			req.systemInfo.applicationType = '今ある電話番号を使う(' + carrier + ')';
			req.systemInfo.landlineNumer = cacheData.m_PhoneInfo.phoneNumber;
			req.systemInfo.contractorName = cacheData.m_PhoneInfo.contractorNameKanji + '(' + cacheData.m_PhoneInfo.contractorNameKana + ')';
		}
		// 新しい電話番号を作る
		else {
			req.systemInfo.newLandlineNumerFlg = true;
			req.systemInfo.applicationType = '新しい電話番号を作る';
		}
		// 配線状況
		if (cacheData.m_PhoneInfo.multiModularJacks)
			req.systemInfo.multiModularJacks = 'あり';
		else
			req.systemInfo.multiModularJacks = 'なし';
		// 回線電話を利用した各種サービスの利用状況
		if (cacheData.m_PhoneInfo.noService)
			req.systemInfo.teleLineService = '利用なし';
		else {
			let serviceList: string[] = new Array();
			if (cacheData.m_PhoneInfo.homeSecurity)
				serviceList.push('ホームセキュリティ');
			if (cacheData.m_PhoneInfo.emergencyCall)
				serviceList.push('緊急通報システム、福祉電話、ホームエレベータの利用');
			if (cacheData.m_PhoneInfo.intercom)
				serviceList.push('インターフォン利用');
			if (cacheData.m_PhoneInfo.homeBusinessPhone)
				serviceList.push('ホームテレフォン・ビジネスフォン利用');
			if (cacheData.m_PhoneInfo.gasWater)
				serviceList.push('ガス・水道等の検針サービス');
			if (cacheData.m_PhoneInfo.phoneRental)
				serviceList.push('電話機を回線業者よりレンタルしている');
			if (cacheData.m_PhoneInfo.otherService)
				serviceList.push('その他');
			for (const service of serviceList) {
				if (!req.systemInfo.teleLineService)
					req.systemInfo.teleLineService = service;
				else
					req.systemInfo.teleLineService += (' / ' + service);
			}
		}
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		const viewCode = '003001';
		if (view.m_Name === 'AddPhoneSelectView') {
			//トリプルの場合：追加判定
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//特定導入方式の場合:001
			if (customer.building[0].buildingTypeCD !== '3' && customer.building[0].buildingTypeCD !== '2') {
				if (mngr.getViewInfo().searchBuildingList && mngr.getViewInfo().searchBuildingList.length > 0) {
					let flg = false;
					for (const introCode of mngr.getMGenralList(view, MypageCodeTypeConstants.INTRODUCTION_CODE, '')) {
						if (mngr.getViewInfo().searchBuildingList.length > 0 && mngr.getViewInfo().searchBuildingList[0].buildingImplementMethodCD === introCode.name1)
							flg = true;
					}
					if (!flg)
						return '/customer_support?no=001' + viewCode;
				}
			}
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2' && mngr.isRentalHouseFd(view)) {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
			//マイグレ
			if (mngr.isMigration(view)) {
				return '/customer_support?no=007' + viewCode;
			}
		}
		else if (view.m_Name === 'AddPhoneConfirmView') {
			//設置場所：006
			const location = /応接室|職場|休憩室|社長室/;
			if (location.test(mngr.getCache().m_PhoneInfo.location)) {
				view.setStorageItem('c_customerSupportCache', mngr.getCache().m_PhoneInfo.location);
				return '/customer_support?no=006' + viewCode;
			}
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): ItscomPhoneAddWizardModel {
		let cacheData = new ItscomPhoneAddWizardModel();
		cacheData.m_PhoneInfo = new PhoneInfoModel();
		cacheData.m_OptionListModel = new Array();
		cacheData.m_OptionConfirmList = new Array();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}
/**
 * 画面ウィザードAPI用モデル
 */
export class ItscomPhoneAddWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_ServiceModel	: MDisplay					= null;			// サービス詳細モデル
	public m_YakkanListModel: MGeneral[]				= null; 		// 約款モデル
	public m_PhoneInfo: PhoneInfoModel 					= null;			// Phoneサービス追加情報モデル
	public m_OptionListModel: PhoneInfoModel[] 			= null;			// オプションリストモデル
	public m_OptionConfirmList: PhoneInfoModel[] 		= null;			// オプション確認リストモデル
	// ------------------------------------------------------------------------
}

