import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { RelocateSelect2View } from '../RelocateSelect2View';
import { WprDI_Object } from '../../../../../wpr-framework/view/di/WprDI_Object';
import { RelocateBaseModel } from '../../../../models/mypage/relocate/RelocateBaseModel';
import { RelocateServiceListModel } from '../../../../models/mypage/relocate/RelocateServiceListModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { Customer } from '../../../../models/bss/customer/Customer';

/**
 * 入力確認モード
 */
export class InputComfirmMode extends WprBaseViewMultiModeInfo<RelocateSelect2View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_RelocateBaseModel: RelocateBaseModel	= null;	// 移設基本モデル
	@WprDI_Object
	private m_TvListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprDI_Object
	private m_NetListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprDI_Object
	private m_PhoneListModel: RelocateServiceListModel[]	= null;	// 移設サービス一覧モデル
	@WprDI_Object
	private m_Customer: Customer	= null;	// 顧客情報
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('InputComfirmMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力済み');
		list.push('未入力');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '入力済み', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		let flg = true;
		for (const model of this.m_TvListModel) {
			if (!model.location)
				flg =false;
			else if ((this.m_Customer.building[0].buildingTypeCD === '3' || this.m_Customer.building[0].buildingTypeCD === '2') && !model.floor)
				flg =false;
			else if (model.location === 'その他' && !model.comment)
				flg =false;
		}
		for (const model of this.m_NetListModel) {
			if (!model.location)
				flg =false;
			else if ((this.m_Customer.building[0].buildingTypeCD === '3' || this.m_Customer.building[0].buildingTypeCD === '2') && !model.floor)
				flg =false;
			else if (model.location === 'その他' && !model.comment)
				flg =false;
		}
		for (const model of this.m_PhoneListModel) {
			if (!model.location)
				flg =false;
			else if ((this.m_Customer.building[0].buildingTypeCD === '3' || this.m_Customer.building[0].buildingTypeCD === '2') && !model.floor)
				flg =false;
			else if (model.location === 'その他' && !model.comment)
				flg =false;
		}
		if (flg && 
			this.m_RelocateBaseModel.tvPortFlg &&
			this.m_RelocateBaseModel.desiredDate1 && 
			this.m_RelocateBaseModel.desiredDate2 && 
			this.m_RelocateBaseModel.desiredDate3 && 
			this.m_RelocateBaseModel.selectTime1 && 
			this.m_RelocateBaseModel.selectTime2 && 
			this.m_RelocateBaseModel.selectTime3 &&
			this.m_RelocateBaseModel.beforeContact &&
			this.m_RelocateBaseModel.sameDayContact &&
			this.m_RelocateBaseModel.presentName
			)
			newMode.addMode('入力済み');
		else 
			newMode.addMode('未入力');
	}
	// --------------------------------------------------------------------------
}
