import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { ModTvComfirmView } from '../tv/revise/mod_tv_comfirm/ModTvComfirmView';
import { ModTvSelect1View } from '../tv/revise/mod_tv_select1/ModTvSelect1View';
import { ModTvSelect2View } from '../tv/revise/mod_tv_select2/ModTvSelect2View';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { Contract } from '../../models/bss/contract/Contract';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { TvDirectModel } from '../../models/mypage/tv/TvDirectModel';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { RegistInfoResponse } from '../../service/models/dto/mypagerenewal/RegistInfoResponse';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class TvChangeWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ModTvSelect1View().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: new ModTvSelect1View().name, path: 'mod_tv_select1'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'コース内容の変更', viewName: new ModTvSelect2View().name, path: 'mod_tv_select2'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new ModTvComfirmView().name, path: 'mod_tv_comfirm'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'mod_tv_agree'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'mod_tv_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_MAIJU_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_FTTH_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OTHER_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_TANAKO_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_DVDHITPOT_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTION, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_SERVICESTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALTYPE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_OPTIONSTATUS, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_AP_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.PAYMENT_DISPNAME, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.IMPORTANT_EXPLANATION));
		req.commonList = new Array();
		req.commonList.push(new MCommon('tvコース名毎充', 'tv_service', 'new_plan', null, null));
		req.commonList.push(new MCommon('tvコース名FTTH', 'tv_service', 'ftth', null, null));
		req.commonList.push(new MCommon('tvコース名HFC', 'tv_service', 'hfc', null, null));
		req.commonList.push(new MCommon('tv追加表示対象', 'tv_service', 'disp_add', null, null));
		req.commonList.push(new MCommon('まいにち充実プラン', 'otoku', 'new_plan_cd', null, null));
		req.commonList.push(new MCommon('お得プラン', 'otoku', 'plan_cd', null, null));
		req.commonList.push(new MCommon('コース名1', 'hanyo1', null, null, null));
		req.commonList.push(new MCommon('コース名2', 'hanyo2', null, null, null));
		req.commonList.push(new MCommon('コース名3', 'hanyo3', null, null, null));
		req.commonList.push(new MCommon('コース名4', 'hanyo4', null, null, null));
		req.commonList.push(new MCommon('コース名5', 'hanyo5', null, null, null));
		req.commonList.push(new MCommon('新コース名1', 'new_plan_hanyo', 'hanyo1', null, null));
		req.commonList.push(new MCommon('新コース名2', 'new_plan_hanyo', 'hanyo2', null, null));
		req.commonList.push(new MCommon('新コース名3', 'new_plan_hanyo', 'hanyo3', null, null));
		req.commonList.push(new MCommon('警告表示対象', 'srvtv', 'prt_course_msg', null, null));
		req.commonList.push(new MCommon('', 'Add_net', 'equ', null, null));
		req.commonList.push(new MCommon('ダミーID', 'Dummy_Equipment', 'dummyid', null, null));
		req.commonList.push(new MCommon('マガジンオプション', 'srvtv', 'magazine_op', null, null));
		req.commonList.push(new MCommon('マックスサービス_FTTH', 'Mod_tv_confirm', 'max_ftth', null, null));
		req.commonList.push(new MCommon('マックスサービス_その他', 'Mod_tv_confirm', 'max', null, null));
		req.commonList.push(new MCommon('4K専用チューナーオプション', 'srvtv', 'tuner_op', null, null));
		req.commonList.push(new MCommon('HVスターチャンネル', 'opt', 'hv_channel', null, null));
		req.commonList.push(new MCommon('非表示オプションCD', 'Mod_tv_entry', 'no_disp_cd', null, null));
		req.commonList.push(new MCommon('マガジン料金', 'charge_mag_info', 'price', null, null));
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PAID_SERVICE, null));
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.AP_FREE_CODE, null));
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.EXCEPT_CONSTRUCTION_FEE, null));
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.RENTALHOUSE_NONFD_OWNERPLAN, null));
		req.ownerFlg = true;
		req.contractFlg = true;
		req.paymentFlg = true;
		req.serviceKey = new Array();
		const target: ContractServiceModel = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TV_MAIJU_SERVICE;
		req.serviceKey.push(target);
		const target2: ContractServiceModel = new ContractServiceModel();
		target2.serviceCd = MypageDispdivcdConstants.TV_FTTH_SERVICE;
		req.serviceKey.push(target2);
		const target3: ContractServiceModel = new ContractServiceModel();
		target3.serviceCd = MypageDispdivcdConstants.TV_OTHER_SERVICE;
		req.serviceKey.push(target3);
		const target4: ContractServiceModel = new ContractServiceModel();
		target4.serviceCd = MypageDispdivcdConstants.TV_TANAKO_SERVICE;
		req.serviceKey.push(target4);
		const target5 = new ContractServiceModel();
		target5.serviceCd = MypageDispdivcdConstants.TV_SERVICE;	// 契約中のコースの月額金額取得のため
		target5.contractId = mngr.getParams().contractId;
		req.serviceKey.push(target5);
		const target6: ContractServiceModel = new ContractServiceModel();
		target6.serviceCd = MypageDispdivcdConstants.TV_AP_SERVICE;
		req.serviceKey.push(target6);
		req.masterFlg = true;
		req.buildingFlg = true;
		req.serviceLineFlg = true;
		//約款
		req.yakkanList = new Array();
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
		if (mngr.getCache()) {
			if (mngr.getCache().m_TvBaseModel.type === MypageDispdivcdConstants.TV_MAIJU_SERVICE || mngr.getCache().m_TvBaseModel.type === MypageDispdivcdConstants.TV_FTTH_SERVICE) {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_HIKARI_TV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_UNLIMITED));
			}
			else if (mngr.getViewInfo() && mngr.getViewInfo().customer.building[0].buildingLineTypeCD  === '2') {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_HIKARI_TV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_UNLIMITED));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_BCAS));
			}
			else {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_CATV));
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.CABLETV_BCAS));
			}
		}
		req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		return req;
	}
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistDataApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistContractRequest{
		const cacheData = mngr.getCache();
		//直接申込判定
		const tvDirectModel: TvDirectModel	= new TvDirectModel();	// テレビ直接申込判定モデル
		//契約情報取得
		const contractId = mngr.getParams().contractId;
		if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
			tvDirectModel.contract = mngr.getContract(view, contractId);
		}
		//お得パック未契約、未希望
		if (!cacheData.m_TvBaseModel.otokuName && !cacheData.m_TvBaseModel.otokuApply) 
			tvDirectModel.otokuFlg = true;
		//ダミー端末判定
		tvDirectModel.dummyFlg = cacheData.m_BeforeTvBaseModel.dummy;
		//解約日判定
		if (!tvDirectModel.contract.cancelDate)
			tvDirectModel.cancelDateFlg = true;
		//申込判定
		tvDirectModel.directFlg = false;
		if (cacheData.m_TvBaseModel.directFlg)
			tvDirectModel.directFlg = true;
		//オプションで専用チューナー4Kをを選択した場合、仮申込み
		const tunerCd = mngr.getMCommonList(view, 'srvtv', 'tuner_op', null, null)[0].commonValue;
		const option = cacheData.m_TvOptionListModel.filter(item => item.detail.optionCD === tunerCd);
		if (option.length > 0) 
			return null;
		//工事なしかつ、解約日なしかつ、ダミー端末でないかつ、お得パック未加入かつ、お得パック未希望の場合、直接申込
		else if (!cacheData.m_TvBaseModel.constractionFlg && tvDirectModel.cancelDateFlg && tvDirectModel.directFlg && tvDirectModel.dummyFlg && tvDirectModel.otokuFlg) {
			const req: RegistContractRequest = new RegistContractRequest();
			req.customerId = mngr.getParams().customerId;
			req.serviceCd = cacheData.m_TvBaseModel.courseCd;
			req.startDate = cacheData.m_TvBaseModel.startDate;
			req.oldContractId = tvDirectModel.contract.contractID;
			req.paymentCustomerId = tvDirectModel.contract.payment[0].paymentCustomerID;
			req.chargeTypeCd = tvDirectModel.contract.payment[0].chargeTypeCD;
			req.equipmentTypeCd = tvDirectModel.contract.equipment[0].equipmentTypeCD;
			req.rentalFlag = tvDirectModel.contract.equipment[0].rentalFlag;
			req.infrastructureTypeCd = tvDirectModel.contract.infrastructure[0].infrastructureTypeCD;
			req.optionList = new Array();
			for (const option of cacheData.m_TvOptionListModel) {
				if (option.select)
					req.optionList.push(option.detail.optionCD);
			}
			return req;
		}
		//その他の場合仮申込み
		else 
			return null;

	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd: string): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.systemInfo = new InquiryMailInfo();
		const cacheData = mngr.getCache();
		const customer = mngr.getViewInfo().customer;
		let errorMsg = null;
		let directResult = '仮申込み';
		//createContractの結果より直接申込判定
		if (resultCd) {
			const contenueList = new Array('2010000', '3010000', '3010002', '3030000', '3050000', '3150000', '3090003');
			if (resultCd === '0') {
				directResult = '直接申込';
			}
			else if (contenueList.includes(resultCd)) {
				switch (resultCd) {
					case '2010000': //customerID＝無効
					errorMsg = "お客様IDが無効エラー";
						break;
					case '3010000': //oldContractID＝無効
					errorMsg = "契約番号が無効エラー";
						break;
					case '3010002': //serviceCD＝登録不可
					errorMsg = "サービス登録不可エラー";
						break;
					case '3030000': //chargeTypeCD＝登録不可
					errorMsg = "料金種別登録不可エラー";
						break;
					case '3050000': //equipmentTypeCD＝登録不可
					errorMsg = "端末種別登録不可エラー";
						break;
					case '3150000': //infrastructureTypeCD＝登録不可
					errorMsg = "インフラ種別登録不可エラー";
						break;
					case '3090003': //optionCD＝登録不可
					errorMsg = "オプション登録不可エラー";
						break;
				}
				directResult = '仮申込み';
			}
			else 
				view.showLogMessage('createContractエラー：' + resultCd);
		}
		//契約情報取得
		const contractId = mngr.getParams().contractId;
		let contract = null;
		if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
			contract = mngr.getContract(view, contractId);
		}
		req.systemInfo.contractId = contract.contractID;
		req.systemInfo.appService = cacheData.m_TvBaseModel.course;
		req.systemInfo.beforeAppService = cacheData.m_BeforeTvBaseModel.course;
		// お得パック状況
		if (cacheData.m_TvBaseModel.otokuName) {
			req.systemInfo.otokuStatus = '契約中';
		}
		else if (!cacheData.m_TvBaseModel.otokuFlag) {
			if (cacheData.m_TvBaseModel.otokuApply) {
				req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
			}
			else if (cacheData.m_TvBaseModel.otokuUnapply) {
				req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
			}
			else {
				req.systemInfo.otokuStatus = '';
			}
		}
		//直接申込エラーメッセージ
		if (errorMsg) 
			req.systemInfo.noticeInfo = '"※システムINFO\nコース変更登録に失敗した為、仮申し込み登録に切り替えられました。【' + errorMsg + '】';
		//レンタル
		req.systemInfo.rental = cacheData.m_TvBaseModel.applyType;
		if (cacheData.m_BeforeTvBaseModel.applyType)
			req.systemInfo.beforeRental = cacheData.m_BeforeTvBaseModel.applyType;
		else
			req.systemInfo.beforeRental = '';
		//オプション
		const optionCdList = new Array();
		req.systemInfo.optionRentalList = new Array();
		for (const option of cacheData.m_TvOptionListModel) {
			if (option.select) {
				req.systemInfo.optionRentalList.push(option.option);
				optionCdList.push(option.detail.optionCD);
			}
		}
		req.systemInfo.beforeOptionNameList = new Array();
		for (const option of cacheData.m_BeforeTvOptionListModel) {
			if (option.option)
				req.systemInfo.beforeOptionNameList.push(option.option);
		}
		//スターチャンネルメッセージ
		const starCd = mngr.getMCommonList (view, 'opt', 'hv_channel', null, null)[0].commonValue;
		const target = optionCdList.filter(item => item === starCd);
		if (target.length > 0 && !errorMsg) {
			req.systemInfo.noticeInfo = 'ユーザからの申込みサービスの登録を受け付けました。\nHVスターチャンネルへの申込みも含まれている為、契約内容の確認を行ってください。';
		}
		else
			req.systemInfo.noticeInfo = null;
		//STB
		req.systemInfo.stb = cacheData.m_TvBaseModel.terminal;
		if (cacheData.m_BeforeTvBaseModel.terminal)
			req.systemInfo.beforeStb = cacheData.m_BeforeTvBaseModel.terminal;
		else
			req.systemInfo.beforeStb = '';
		//マガジン
		req.systemInfo.beforeMagazinApp = cacheData.m_BeforeTvBaseModel.magazine;
		//支払い方法
		req.systemInfo.paymentDispname = cacheData.m_TvBaseModel.paymentDispname;
		// 工事
		if (cacheData.m_TvBaseModel.constractionFlg) {
			//希望日時
			req.systemInfo.desiredDate1 = cacheData.m_TvBaseModel.desiredDate1.replace(/-/g, '/');
			req.systemInfo.desiredDate2 = cacheData.m_TvBaseModel.desiredDate2.replace(/-/g, '/');
			req.systemInfo.desiredDate3 = cacheData.m_TvBaseModel.desiredDate3.replace(/-/g, '/');
			req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime1);
			req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime2);
			req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_TvBaseModel.selectTime3);
			//セキュリティ会社
			if (cacheData.m_TvBaseModel.securityFlg)
				req.systemInfo.securityComment = cacheData.m_TvBaseModel.securityComment;
			else
				req.systemInfo.securityComment = 'なし';
			//工事費
			if (cacheData.m_TvBaseModel.payUnit)
				req.systemInfo.constructionCost = cacheData.m_TvBaseModel.constrAmount + cacheData.m_TvBaseModel.payUnit;
			else
				req.systemInfo.constructionCost = cacheData.m_TvBaseModel.constrAmount;
			//連絡先等
			req.systemInfo.beforeContact = cacheData.m_TvBaseModel.beforeContact;
			req.systemInfo.sameDayContact = cacheData.m_TvBaseModel.sameDayContact;
			req.systemInfo.witness = cacheData.m_TvBaseModel.presentName;
		}
		// キャンペーン
		if (cacheData.m_TvBaseModel.campaignCode) {
			req.systemInfo.campaignFlg = true;
			req.systemInfo.campaignGroupName = cacheData.m_TvBaseModel.campaignGroup.campaignGroupName;
			req.systemInfo.campaignList = cacheData.m_TvBaseModel.campaignList;
		}
		//テンプレート設定
		//直接申込
		let mailconstants = null;
		if (directResult === '直接申込' && !req.systemInfo.noticeInfo) {
			mngr.getCache().m_TvBaseModel.directResult = '直接申込';
			if (cacheData.m_TvBaseModel.constractionFlg) 
				req.systemInfo.constractionFlg = true;
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TV_変更_直接';
			});
			req.systemInfo.equipmentInfo = '';
		}
		//仮申込み、ダミー端末でない場合
		else if (cacheData.m_BeforeTvBaseModel.dummy) {
			mngr.getCache().m_TvBaseModel.directResult = '仮申込み'
			if (cacheData.m_TvBaseModel.constractionFlg) 
				req.systemInfo.constractionFlg = true;
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TV_変更_仮申込み';
			});
			req.systemInfo.equipmentInfo = '';
		}
		//仮申込、ダミー端末の場合
		else {
			mngr.getCache().m_TvBaseModel.directResult = '仮申込み'
			if (cacheData.m_TvBaseModel.constractionFlg) 
				req.systemInfo.constractionFlg = true;
			mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'TV_変更_仮申込みダミー';
			});
			req.systemInfo.equipmentInfo = 'ダミーの端末情報が登録されているため、お客様へ、STB-ID、CASカード番号、またはMACアドレスのヒアリングをお願いいたします。';
		}
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		if (view.m_Name === 'ModTvSelect1View') {
			//トリプルの場合：追加判定
			const viewCode = '001002';
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2' && mngr.isRentalHouseFd(view)) {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
			//マイグレ
			if (mngr.isMigration(view)) {
				return '/customer_support?no=007' + viewCode;
			}
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TvNewWizardModel {
		let cacheData = new TvNewWizardModel();
		cacheData.m_TvCourseModel = new Array();
		cacheData.m_TvBaseModel = new TvBaseModel();
		cacheData.m_TvOptionListModel = new Array();
		cacheData.m_BeforeTvOptionListModel = new Array();
		cacheData.m_BeforeTvBaseModel = new TvBaseModel();
		cacheData.m_Contract = new Contract();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}