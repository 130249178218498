import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { YakkanListModel } from '../../../service/models/apimodels/YakkanListModel';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ProgressBarData } from '../progress_bar/ProgressBarView';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { AgreeMode } from './mode/AgreeMode';
import { MGeneral } from '../../../service/models/entity/MGeneral';
import { GetMGeneralRequest } from '../../../service/models/dto/mypagerenewal/GetMGeneralRequest';
import { YakkaCheckListMode } from './mode/YakkaCheckListMode';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { NameChangeApiModel, NameChangeWizardFlow } from '../../wizard/NameChangeWizardFlow';
import { RegistInfoRequest } from '../../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { BaseService } from '../../../service/base/BaseService';

/**
 * 約款画面
 */
export class CommonAgreeView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('CommonAgreeView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_confirm: WprControlInfo	= null;	// 承諾
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprListData('YakkanListModel')
	private m_YakkanListModel: MGeneral[] = new Array();	// 約款リストモデル
	// -------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();	// 約款モード
	@WprViewMode
	private m_YakkaCheckListMode = new YakkaCheckListMode();	// 約款チェックリストモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('yakkanName', '名称1', 'YakkanListModel', { bindName: 'name1' });
		this.addListConfig('text1', '名称1', 'YakkanListModel');
		this.addListConfig('text2', '名称1', 'YakkanListModel');
		this.addConfig('confirm', '承諾', null);
		this.addListConfig('fileSize', '数値1', 'YakkanListModel', { bindName: 'num1' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addListAction('yakkan', 'YakkanListModel', this.onYakkan);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const req: GetMGeneralRequest = new GetMGeneralRequest();
			req.generalList = this.m_MypageWizardMngr.activeFlow.getWizardApiModel(this.m_MypageWizardMngr, this).yakkanList;
			this.m_CommonService.getMGeneralList(this, req, (result) => {
				let textFlg: boolean = false;
				let count: number = 0
				for (const yakkan of result.generalModelList) {
					count ++;
					if (yakkan.general_cd === '19') {
						textFlg = true;
						yakkan.generalList[0].text1 = '必須確認項目';
					}
					else if (textFlg && count === 2) 
						yakkan.generalList[0].text1 = '詳細を確認される方はこちら';
					this.m_YakkanListModel.push(yakkan.generalList[0]);
				}
				this.setListData('YakkanListModel', this.m_YakkanListModel);
				// 重要説明事項がある場合のみ
				if (textFlg) 
					this.setViewMode('agree', '未承諾');
				else
					this.setViewMode('agree', '承諾');
				this.refreshView();
			});
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		// 旧名義人　かつ　申込み種別「改姓」以外の場合
		if ((window.location.href).includes('name_change_agree')) {
			const cacheData = this.m_MypageWizardMngr.getCache();
			if (cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn !== '1') {
				const req: RegistInfoRequest = new RegistInfoRequest();
				req.customerId = this.m_MypageWizardMngr.getParams().customerId;
				const nameChangeApiModel = new NameChangeApiModel();
				// 問合せ登録
				this.m_CommonService.registInfo(this, nameChangeApiModel.createInquiry(this, cacheData, req), (result) => {
					if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS)
						this.m_MypageWizardMngr.goNext(this);
					 else
						this.pushPath('/error');
				});
			}
			else
				this.m_MypageWizardMngr.goNext(this);
		}
		else
			this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 約款名
	 */
	public onYakkan(param: any, item: any): void {
		item.flag1 = true;
		const noCheckYakkan = this.m_YakkanListModel.filter(data => data.generalCd === '19' && !data.flag1);
		if (noCheckYakkan.length === 0)
			this.setViewMode('agree', '承諾');
		window.open(item.name2);
	}
	// --------------------------------------------------------------------------
}
