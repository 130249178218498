import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { TvBaseModel } from '../../../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../../../models/mypage/tv/TvOptionListModel';
import { AddServiceModel } from '../../../../service/models/apimodels/AddServiceModel';
import { GetConstructionCostRequest } from '../../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { GetSimulationRequest } from '../../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { MCampaign } from '../../../../service/models/entity/primary/MCampaign';
import { MCampaignGroup } from '../../../../service/models/entity/primary/MCampaignGroup';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { AgreeMode } from '../../../common/common_agree/mode/AgreeMode';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { OptionListMode } from './mode/OptionListMode';

/**
 * テレビ追加確認
 */
export class AddTvComfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddTvComfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('TvOptionListModel')
	private m_TvOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル(画面表示分)
	@WprModel('CampaignGroup')
	private m_CampaignGroup: MCampaignGroup	= null;	// キャンペーングループモデル
	@WprListData('CampaignListModel')
	private m_CampaignListModel: MCampaign[]	= null;	// キャンペーンリストモデル
	@WprModel('TvBaseModel')
	private m_TvBaseModel: TvBaseModel	= null;	// テレビ基本情報モデル
	@WprModel('checkBox')
	private m_checkBox: string	= null;	// checkBox
	// --------------------------------------------------------------------------

	// private変数 ---------------------------------------------------------------
	private m_AllOptionListModel: TvOptionListModel[]	= null;	// テレビオプションリストモデル(全件)
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();	// 約款モード
	@WprViewMode
	private m_OptionListMode = new OptionListMode();	// optionListMode
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('amount', '金額', 'TvBaseModel', { converter: '3桁カンマ' });
		this.addConfig('course', 'コース', 'TvBaseModel');
		this.addConfig('terminal', '機器名', 'TvBaseModel');
		this.addConfig('magazine', 'マガジン', 'TvBaseModel');
		this.addListConfig('option', 'オプション', 'TvOptionListModel');
		this.addConfig('breforeFee', '変更前料金', 'TvBaseModel.monthlyFee', { converter: '3桁カンマ' });
		this.addConfig('afterFee', '変更後料金', 'TvBaseModel.monthlyFee', { converter: '3桁カンマ' });
		this.addConfig('otokuName', '月額利用料', 'TvBaseModel');
		this.addConfig('confirm', 'checkBox', 'checkBox', { bindModel: true });
		this.addConfig('floor', '階数', 'TvBaseModel');
		this.addConfig('location', '場所', 'TvBaseModel');
		this.addConfig('comment', 'フリー入力', 'TvBaseModel');
		this.addConfig('desiredDate1', '第一希望', 'TvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'TvBaseModel');
		this.addConfig('desiredDate2', '第二希望', 'TvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'TvBaseModel');
		this.addConfig('desiredDate3', '第三希望', 'TvBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'TvBaseModel');
		// this.addConfig('campaignDiscount', '数値1', 'TvBaseModel.campaign', { bindName: 'num1', converter: '3桁カンマ' });
		this.addConfig('constrAmount', '工事費', 'TvBaseModel', { converter: '3桁カンマ' });
		this.addConfig('payUnit', '工事費', 'TvBaseModel');
		this.addConfig('campaignName', 'キャンペーン名', 'CampaignGroup', { bindName: 'campaignGroupDispName' });
		this.addConfig('campaignExplanation', 'キャンペーン文言', 'CampaignGroup', { bindName: 'explanation' });
		this.addConfig('beforeContact', '前日連絡先', 'TvBaseModel');
		this.addConfig('sameDayContact', '当日連絡先', 'TvBaseModel');
		this.addConfig('name', '立ち合い者名', 'TvBaseModel', { bindName: 'presentName' });
		this.addConfig('reformDisp', 'リフォーム', 'TvBaseModel');
		this.addConfig('securityDisp', 'セキュリティ会社', 'TvBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			//キャッシュデータ取得
			this.m_TvBaseModel = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			this.m_AllOptionListModel = this.m_MypageWizardMngr.getCache().m_TvOptionListModel;
			//表示用データ設定
			this.getDisplayData();
			//月額取得
			this.paymentSimulation();
			this.refreshView()
		});
	}
	
	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if(name === 'confirm')	{
			if(value === true) {
				this.setViewMode('agree','承諾');
			}
			else {
				this.setViewMode('agree','未承諾');
			}
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
	// privateメソッド  ----------------------------------------------------------
	/**
	 * 料金シミュレーション
	 */
	private paymentSimulation() {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();	// 追加サービスモデルリスト
		// コース
		const service = new AddServiceModel;
		service.serviceCd = this.m_TvBaseModel.courseCd;
		req.addServiceList.push(service);
		req.addServiceList[0].optionCdList = new Array();
		// オプション
		for (const option of this.m_TvOptionListModel) {
			if (option.select) {
				req.addServiceList[0].optionCdList.push(option.detail.optionCD);
			}
		}
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_TvBaseModel.monthlyFee = result;
			// 新しい月額利用料
			if (result.minusFlg) {
				this.m_TvBaseModel.monthlyFee.afterFee = String(Number(this.m_TvBaseModel.monthlyFee.afterFee) + 396);
			}
			this.refreshModel();
		});
	}

	/**
	 * 表示用設定
	 */
	private getDisplayData() {
		// お得パック加入判定
		const contract = this.m_MypageWizardMngr.getViewInfo().contractModel;
		const paymentList  = this.m_MypageWizardMngr.getViewInfo().customerPayment;
		const customer = this.m_MypageWizardMngr.getViewInfo().customer;
		if (paymentList && !this.m_MypageWizardMngr.showOtokuPlan(this, paymentList[0].payment[0], contract.planList)) {
			if (ObjectUtil.isNotNullOrUndefined(contract.planList ) && contract.planList.length !== 0) {
				this.setViewMode('お得パック','加入済み');
				this.setViewMode('新月額利用料','※');
				// 適用中お得パック名取得
				this.m_TvBaseModel.otokuName = this.m_MypageWizardMngr.getOtokuPlanName(this, contract.planList).dispnamep;
			}
			else if (this.m_TvBaseModel.otokuApply)
				this.setViewMode('新月額利用料','※');
			else
				this.setViewMode('新月額利用料','料金');
		}
		else if (this.m_TvBaseModel.otokuApply)
			this.setViewMode('新月額利用料','※');
		else
			this.setViewMode('新月額利用料','料金');
		//表示用設定(magazine)
		if (this.m_TvBaseModel.magazineApply)
			this.m_TvBaseModel.magazine = '申込む';
		else
			this.m_TvBaseModel.magazine = '申込まない';
		//オプション一覧
		this.m_TvOptionListModel = this.m_AllOptionListModel.filter(item => item.select);
		// 表示用設定(#72624対応)---------------------------------------------
		for (const option of this.m_TvOptionListModel) {
			if (option.detail.optionCD === '0102000157'|| option.detail.optionCD === '0102000292') {
				this.setViewMode('東映', '表示');
			}
			if (option.detail.optionCD === '0102000158' || option.detail.optionCD === '0102000273') {
				this.setViewMode('衛星', '表示');
			}
		}
		// ------------------------------------------------------------------
		if (this.m_TvOptionListModel.length === 0)
		 this.m_TvOptionListModel.push(new TvOptionListModel())
		this.setViewData('sentenceData', this.m_TvOptionListModel.length + 1);
		this.setListData('TvOptionListModel', this.m_TvOptionListModel);
		//チェックボックス表示判定
		if (this.m_TvBaseModel.terminalCd === '5') {
			this.setViewMode('agree','未承諾');
			this.setViewMode('ケーブルプラスSTB','表示');
		}
		else 
			this.setViewMode('agree','承諾');
		//画面サイズによるレイアウトの設定
		if (window.outerWidth > 440) {
			this.setViewMode('オプション','PC');
		}
		else {
			this.setViewMode('オプション','SP');
		}
		//リフォーム
		if (this.m_TvBaseModel.reformFlg)
			this.m_TvBaseModel.reformDisp = 'あり';
		else
			this.m_TvBaseModel.reformDisp = 'なし';
		//セキュリティ会社
		if (this.m_TvBaseModel.securityFlg)
			this.m_TvBaseModel.securityDisp = this.m_TvBaseModel.securityComment;
		else
			this.m_TvBaseModel.securityDisp = 'なし';
		//工事費
		//戸建、戸建賃貸の場合お見積り
		if (customer.building[0].buildingTypeCD === '3' || customer.building[0].buildingTypeCD === '2') {
			this.m_TvBaseModel.constrAmount = 'お見積り';
			this.m_TvBaseModel.payUnit = null;
		}
		//その他の場合金額表示
		else {
			const req: GetConstructionCostRequest = new GetConstructionCostRequest();
			req.constructionCategoryCd = '02';
			req.applicationDetailsCd = '01';
			req.temporaryCostCategoryCd = '01';
			req.multipleFlg = false;
			req.freeFlg = true;
			this.m_MypageWizardMngr.getConstructionCost(this, req, null, false, false, result => {
				if (result) {
					if (result.temporaryCost.length > 0) {
						this.m_TvBaseModel.constrAmount = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost.toString());
						this.m_TvBaseModel.payUnit = '円';
					}
					else {
						this.m_TvBaseModel.constrAmount = 'お見積り';
						this.m_TvBaseModel.payUnit = null;
					}
					this.refreshView();
				}
			});
		}
		//キャンペーン
		if (this.m_TvBaseModel.campaignList) {
			this.setViewMode('キャンペーンコード', '有');
			this.m_CampaignGroup = this.m_TvBaseModel.campaignGroup;
			this.m_CampaignListModel = this.m_TvBaseModel.campaignList;
			this.setListData('CampaignListModel', this.m_CampaignListModel);
			// this.setViewMode('初期費用キャンペーン', this.m_TvBaseModel.campaign.subCd1 === '01' ? '有' : '無');
			// if (this.m_TvBaseModel.campaign.subCd1 === '01' && this.m_TvBaseModel.campaign.subCd2 === '03')
			// 	this.m_TvBaseModel.campaign.num1 = Number(this.m_TvBaseModel.constrAmount);
		}
		else
			this.setViewMode('キャンペーンコード', '無');
		//物件判定
		if (customer.building[0].buildingTypeCD === '1')
			this.setViewMode('物件', '集合');
		else if (customer.building[0].buildingTypeCD === '3' || customer.building[0].buildingTypeCD === '2')
			this.setViewMode('物件', '戸建');
	}
	// --------------------------------------------------------------------------
}
