import { WprControlInfo } from '../../../../wpr-framework/view/control/WprControlInfo';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprViewMode } from '../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_Control } from '../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { Contract } from '../../../models/bss/customer/Contract';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { MCommon } from '../../../service/models/entity/primary/MCommon';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { Phoneconst } from './Phoneconst';
import { PhoneOtokuMode } from './mode/PhoneOtokuMode';
import { PhoneInfoModel } from './models/PhoneInfoModel';
import { WprValidError } from '../../../../wpr-framework/view/validation/WprValidError';
import { OtherLocationMode } from './mode/OtherLocationMode';
import { InputConfirmMode } from './mode/InputConfirmMode';
import { Customer } from '../../../models/bss/customer/Customer';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';
import { ErrorModel } from '../../../models/mypage/smart/ErrorModel';

/**
 * 追加サービス申込み
 */
export class AddPhoneSelectView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddPhoneSelectView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_courseCheck: WprControlInfo = null;	// コース選択
	@WprDI_Control
	private m_equipmentProvidingCheck: WprControlInfo = null;	// 機器のご提供方法選択
	@WprDI_Control
	private m_yesOtoku: WprControlInfo = null;	// お得パック希望する
	@WprDI_Control
	private m_noOtoku: WprControlInfo = null;	// お得パック希望しない
	@WprDI_Control
	private m_desiredDate1: WprControlInfo	= null;	// 第一希望
	@WprDI_Control
	private m_selectTime1: WprControlInfo	= null;	// 第一希望　時間帯
	@WprDI_Control
	private m_desiredDate2: WprControlInfo	= null;	// 第二希望
	@WprDI_Control
	private m_selectTime2: WprControlInfo	= null;	// 第二希望　時間帯
	@WprDI_Control
	private m_desiredDate3: WprControlInfo	= null;	// 第三希望
	@WprDI_Control
	private m_selectTime3: WprControlInfo	= null;	// 第三希望　時間帯
	@WprDI_Control
	private m_beforeContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_sameDayContact: WprControlInfo	= null;	// 前日連絡先
	@WprDI_Control
	private m_securityFlg: WprControlInfo	= null;	// セキュリティフラグ
	@WprDI_Control
	private m_location: WprControlInfo		= null;	// 場所
	@WprDI_Control
	private m_phoneNumerSelect: WprControlInfo	= null;	// 電話番号
	@WprDI_Control
	private m_lineType: WprControlInfo	= null;	// 回線業者
	@WprDI_Control
	private m_auPhoneUsing: WprControlInfo	= null;	// au携帯利用
	@WprDI_Control
	private m_noService: WprControlInfo	= null;	// 利用なし
	@WprDI_Control
	private m_homeSecurity: WprControlInfo	= null;	// ホームセキュリティ
	@WprDI_Control
	private m_emergencyCall: WprControlInfo	= null;	// 緊急通報システム、福祉電話、ホームエレベータの利用
	@WprDI_Control
	private m_homeBusinessPhone: WprControlInfo	= null;	// ホームテレフォン・ビジネスフォン利用
	@WprDI_Control
	private m_gasWater: WprControlInfo	= null;	// ガス・水道等の検針サービス
	@WprDI_Control
	private m_phoneRental: WprControlInfo	= null;	// 電話機を回線業者よりレンタルしている
	@WprDI_Control
	private m_otherService: WprControlInfo	= null;	// その他
	@WprDI_Control
	private m_auPhoneNumber: WprControlInfo	= null;	// au電話番号
	@WprDI_Control
	private m_phoneNumber: WprControlInfo	= null;	// 固定電話番号
	@WprDI_Control
	private m_contractorNameKana: WprControlInfo	= null;	// 契約者名 ( カナ )
	@WprDI_Control
	private m_contractorNameKanji: WprControlInfo	= null;	// 契約者名 ( 漢字 )
	@WprDI_Control
	private m_otherLineComment: WprControlInfo	= null;	// 回線業者その他フリー入力
	@WprDI_Control
	private m_intercom: WprControlInfo	= null;	// インターフォン利用
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('PhoneInfoModel')
	private m_PhoneInfo: PhoneInfoModel 		= null;			// Phoneサービス追加情報モデル
	@WprListData('OptionListModel')
	private m_OptionListModel: PhoneInfoModel[]	= null;	// オプションリスト
	@WprListData('ErrorModelList')
	private m_ErrorModelList: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------
	
	// private変数  --------------------------------------------------------------
	private m_Customer:	Customer				= null;			// 顧客情報
	private m_NumNoticeFlg: boolean				= null;
	private m_CallWaitNumFlg: boolean			= null;
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_PhoneOtokuMode = new PhoneOtokuMode();	// お得パックモード
	@WprViewMode
	private m_OtherLocationMode = new OtherLocationMode();	// フリー入力モード
	@WprViewMode
	private m_InputConfirmMode = new InputConfirmMode();	// 入力確認モード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	*/
	public onRegisterConfig(): void {
		this.addConfig('course', 'コース', 'PhoneInfoModel');
		this.addConfig('equipmentProviding', '機器の提供方法', 'PhoneInfoModel');
		this.addConfig('yesOtoku', 'お得パック希望する', 'PhoneInfoModel');
		this.addConfig('noOtoku', 'お得パック希望しない', 'PhoneInfoModel');
		this.addConfig('courseCheck', '申込みコース', 'PhoneInfoModel', { readOnly: true, disabled: true });
		this.addConfig('equipmentProvidingCheck', '機器のご提供方法', 'PhoneInfoModel', { readOnly: true, disabled: true });
		this.addConfig('floor', '階数', 'PhoneInfoModel');
		this.addConfig('location', '場所', 'PhoneInfoModel', {hissu: true});
		this.addConfig('comment', 'フリー入力', 'PhoneInfoModel', { maxLength: 100 });
		this.addConfig('desiredDate1', '第一希望', 'PhoneInfoModel', { hissu: true, emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime1', '第一希望　時間帯', 'PhoneInfoModel', { hissu: true, emptyValue: null });
		this.addConfig('desiredDate2', '第二希望', 'PhoneInfoModel', { hissu: true, emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime2', '第二希望　時間帯', 'PhoneInfoModel', { hissu: true, emptyValue: null });
		this.addConfig('desiredDate3', '第三希望', 'PhoneInfoModel', { hissu: true, emptyValue: null, max: this.m_MypageWizardMngr.getMaxReqDate(), min: this.m_MypageWizardMngr.getMinReqDate() });
		this.addConfig('selectTime3', '第三希望　時間帯', 'PhoneInfoModel', { hissu: true, emptyValue: null });
		this.addConfig('reformFlg', 'リフォームフラグ', 'PhoneInfoModel');
		this.addConfig('tvPortFlg', 'TV端子確認フラグ', 'PhoneInfoModel');
		this.addListConfig('check', '選択', 'OptionListModel');
		this.addListConfig('optionName', 'オプション名', 'OptionListModel');
		this.addListConfig('notes', '注釈', 'OptionListModel');
		this.addListConfig('optionFee', 'オプション月額料金', 'OptionListModel');
		this.addListConfig('payUnit', '料金単位', 'OptionListModel');
		this.addConfig('securityFlg', 'セキュリティフラグ', 'PhoneInfoModel');
		this.addConfig('securityComment', 'セキュリティフリー入力', 'PhoneInfoModel');
		this.addConfig('prankCallFlg', '迷惑電話同意フラグ', 'PhoneInfoModel');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorModelList');
		this.addConfig('beforeContact', '前日連絡先', 'PhoneInfoModel', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'PhoneInfoModel', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '当日立ち合い者', 'PhoneInfoModel');
		this.addConfig('moving', '引っ越し', 'PhoneInfoModel');
		this.addConfig('phoneNumerSelect', '電話番号', 'PhoneInfoModel');
		this.addConfig('lineType', '回線業者', 'PhoneInfoModel');
		this.addConfig('phoneNumber', '固定電話番号', 'PhoneInfoModel', { maxLength: 11, validation: '整数' });
		this.addConfig('contractorNameKana', '契約者名 ( カナ )', 'PhoneInfoModel');
		this.addConfig('contractorNameKanji', '契約者名 ( 漢字 )', 'PhoneInfoModel');
		this.addConfig('auPhoneUsing', 'au携帯利用', 'PhoneInfoModel');
		this.addConfig('auPhoneNumber', 'au電話番号', 'PhoneInfoModel', { maxLength: 11, validation: '整数' });
		this.addConfig('otherLineComment', '回線業者その他フリー入力', 'PhoneInfoModel');
		this.addConfig('multiModularJacks', 'モジュラージャックを複数箇所利用', 'PhoneInfoModel');
		this.addConfig('noService', '利用なし', 'PhoneInfoModel');
		this.addConfig('homeSecurity', 'ホームセキュリティ', 'PhoneInfoModel');
		this.addConfig('emergencyCall', '緊急通報システム、福祉電話、ホームエレベータの利用', 'PhoneInfoModel');
		this.addConfig('intercom', 'インターフォン利用', 'PhoneInfoModel');
		this.addConfig('homeBusinessPhone', 'ホームテレフォン・ビジネスフォン利用', 'PhoneInfoModel');
		this.addConfig('gasWater', 'ガス・水道等の検針サービス', 'PhoneInfoModel');
		this.addConfig('phoneRental', '電話機を回線業者よりレンタルしている', 'PhoneInfoModel');
		this.addConfig('otherService', 'その他', 'PhoneInfoModel');
		this.addConfig('phoneServiceConfirm', '電話サービス注意事項', 'PhoneInfoModel');
	}
	// --------------------------------------------------------------------------
	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		if (name === 'yesOtoku')
			this.m_noOtoku.value = false;
		if (name === 'noOtoku')
			this.m_yesOtoku.value = false;
		if (name === 'securityFlg') {
			if (value)
				this.setViewMode('セキュリティ会社', '表示');
			else
				this.setViewMode('セキュリティ会社', '非表示');
		}
		if (name === 'phoneNumerSelect' && value === '1') {
			this.m_lineType.value = null;
		}
		if (name === 'multiModularJacks') {
			if (value)
				this.setViewMode('配線状況', '選択');
			else
				this.setViewMode('配線状況', '未選択');
		}
		if (name === 'noService') {
			this.m_homeSecurity.value = false;
			this.m_emergencyCall.value = false;
			this.m_homeBusinessPhone.value = false;
			this.m_intercom.value = false;
			this.m_gasWater.value = false;
			this.m_phoneRental.value = false;
			this.m_otherService.value = false;
			this.setViewMode('備考_ホームセキュリティ', '非表示');
			this.setViewMode('備考_緊急通報備考', '非表示');
			this.setViewMode('備考_インターフォン', '非表示');
			this.setViewMode('備考_ホーム・ビジネステレフォン', '非表示');
			this.setViewMode('備考_ガス・水道', '非表示');
			this.setViewMode('備考_電話機レンタル', '非表示');
			this.setViewMode('備考_その他', '非表示');
		}
		// // 「利用なし」以外
		let serviceList = ['homeSecurity', 'emergencyCall', 'homeBusinessPhone', 'gasWater', 'phoneRental', 'otherService'];
		if (serviceList.includes(name))
			this.m_noService.value = false;
		if (name === 'homeSecurity') {
			if (value)
				 this.setViewMode('備考_ホームセキュリティ', '表示');
			else
				this.setViewMode('備考_ホームセキュリティ', '非表示');
		}
		if (name === 'emergencyCall') {
			if (value)
				this.setViewMode('備考_緊急通報備考', '表示');
			else
				this.setViewMode('備考_緊急通報備考', '非表示');
		}
		if (name === 'intercom') {
			if (value)
				this.setViewMode('備考_インターフォン', '表示');
			else
				this.setViewMode('備考_インターフォン', '非表示');
		}
		if (name === 'homeBusinessPhone') {
			if (value)
				this.setViewMode('備考_ホーム・ビジネステレフォン', '表示');
			else
				this.setViewMode('備考_ホーム・ビジネステレフォン', '非表示');
		}
		if (name === 'gasWater') {
			if (value)
				this.setViewMode('備考_ガス・水道', '表示');
			else
				this.setViewMode('備考_ガス・水道', '非表示');
		}
		if (name === 'phoneRental') {
			if (value)
				this.setViewMode('備考_電話機レンタル', '表示');
			else
				this.setViewMode('備考_電話機レンタル', '非表示');
		}
		if (name === 'otherService') {
			if (value)
				this.setViewMode('備考_その他', '表示');
			else
				this.setViewMode('備考_その他', '非表示');
		}
		this.changeQuestion();
		this.updateMode();
		return true;
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check' && listName === 'OptionListModel') {
			if (row.optionName === '迷惑電話自動ブロック') {
				if (value)
					this.setViewMode('迷惑電話', '表示');
				else
					this.setViewMode('迷惑電話', '非表示');
			}
			this.updateMode();
			this.refreshListModel('OptionListModel');
		}
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		this.m_ErrorModelList = new Array();
		if (actionName == 'next') {
			// 希望日時
			const hisssu1: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate1.value, this.m_selectTime1.value);
			const hisssu2: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate2.value, this.m_selectTime2.value);
			const hisssu3: boolean = !this.m_MypageWizardMngr.isNotEmpty(this.m_desiredDate3.value, this.m_selectTime3.value);
			if (hisssu1 || hisssu2 || hisssu3) {
				const itemStr: string = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: hisssu1, dispVal: '第一希望' }, { flag: hisssu2, dispVal: '第二希望' }, { flag: hisssu3, dispVal: '第三希望' },]);
				const model = new ErrorModel();
				model.errorMessage = itemStr + '：日付、時間帯のいずれかが入力されていません。再度入力してください。';
				this.m_ErrorModelList.push(model);
			}
			else {
				const selectTime1: string = this.m_desiredDate1.value + this.m_selectTime1.value;
				const selectTime2: string = this.m_desiredDate2.value + this.m_selectTime2.value;
				const selectTime3: string = this.m_desiredDate3.value + this.m_selectTime3.value;
				let reqTimeErrorFlg1: boolean = false;
				let reqTimeErrorFlg2: boolean = false;
				let reqTimeErrorFlg3: boolean = false;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate1.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime1.value) && selectTime1 === selectTime2) reqTimeErrorFlg1 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate2.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime2.value) && selectTime1 === selectTime3) reqTimeErrorFlg2 = true;
				if (ObjectUtil.isNotNullOrUndefined(this.m_desiredDate3.value) && ObjectUtil.isNotNullOrUndefined(this.m_selectTime3.value) && selectTime2 === selectTime3) reqTimeErrorFlg3 = true;
				if (reqTimeErrorFlg1 || reqTimeErrorFlg2 || reqTimeErrorFlg3) {
					let itemStr: string;
					if (reqTimeErrorFlg1 === true && reqTimeErrorFlg3 == true) 
						itemStr = '第一希望と第二希望と第三希望';
					else 
						itemStr = this.m_MypageWizardMngr.setErrorReqDateItem([{ flag: reqTimeErrorFlg1, dispVal: '第一希望と第二希望' }, { flag: reqTimeErrorFlg2, dispVal: '第一希望と第三希望' }, { flag: reqTimeErrorFlg3, dispVal: '第二希望と第三希望' },]);
					const model = new ErrorModel();
					model.errorMessage = itemStr + '：同じ希望日時が入力されています。別の日時を指定してください。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.m_desiredDate1.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第一希望に' + date + '以降を入力してください。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_desiredDate2.value < this.m_MypageWizardMngr.getMinReqDate() ) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第二希望に' + date + '以降を入力してください。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_desiredDate3.value < this.m_MypageWizardMngr.getMinReqDate()) {
				const date = this.m_MypageWizardMngr.convertToDate(this.m_MypageWizardMngr.getMinReqDate());
				const model = new ErrorModel();
				model.errorMessage = '第三希望に' + date + '以降を入力してください。';
				this.m_ErrorModelList.push(model);
			}
			// 前日連絡先
			if (!this.m_beforeContact.value.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage =  '前日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
			// 当日連絡先
			if (!this.m_sameDayContact.value.match(/^[0-9]+$/)) {
				const model = new ErrorModel();
				model.errorMessage = '当日連絡先は半角数字で入力してください。';
				this.m_ErrorModelList.push(model);
			}
			// オプション判定
			const numReq = this.m_OptionListModel.filter(data => data.optionName === '番号通知リクエスト');
			if (numReq && numReq[0].check) {
				const list = this.m_OptionListModel.filter(data => data.check && data.generalCd === numReq[0].subCd);
				if (ObjectUtil.isNullOrUndefined(list) || list.length === 0) {
					const model = new ErrorModel();
					model.errorMessage = '番号通知リクエストは「発信番号表示」との同時加入が必須となります。';
					this.m_ErrorModelList.push(model);
				}
			}
			const callNotice = this.m_OptionListModel.filter(data => data.optionName === '割込番号表示');
			if (callNotice && callNotice[0].check) {
				const subCdList = callNotice[0].subCd.split('|');
				const list = this.m_OptionListModel.filter(data => (data.check && data.generalCd === subCdList[0]) || (data.check &&data.generalCd === subCdList[1]));
				if (ObjectUtil.isNullOrUndefined(list) || list.length < 2 ) {
					const model = new ErrorModel();
					model.errorMessage = '割込番号表示は「割込通話」と「発信番号通知」への同時加入が必須となります。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.getViewMode('固定電話情報') === '表示') {
				if (!this.m_phoneNumber.value) {
					const model = new ErrorModel();
					model.errorMessage = '固定電話 お電話番号：入力必須項目です。';
					this.m_ErrorModelList.push(model);
				}
				if (this.m_lineType.value === '7' && !this.m_otherLineComment.value) {
					const model = new ErrorModel();
					model.errorMessage = 'その他 会社名：入力必須項目です。';
					this.m_ErrorModelList.push(model);
				}
				if (!this.m_contractorNameKana.value) {
					const model = new ErrorModel();
					model.errorMessage = 'ご契約者様名（カナ）：入力必須項目です。';
					this.m_ErrorModelList.push(model);
				}
			// 全角カタカナ入力チェック
				else if (!this.m_contractorNameKana.value.match('^[ァ-ンヴー]*$')) {
					const model = new ErrorModel();
					model.errorMessage = 'ご契約者様名（カナ）：全角カタカナで入力してください。';
					this.m_ErrorModelList.push(model);
				}
				if (!this.m_contractorNameKanji.value) {
					const model = new ErrorModel();
					model.errorMessage = 'ご契約者様名（漢字）：入力必須項目です。';
					this.m_ErrorModelList.push(model);
				}
			}
			if (this.getViewMode('au携帯電話入力欄') === '表示' && !this.m_auPhoneNumber.value) {
				const model = new ErrorModel();
				model.errorMessage = 'au携帯 お電話番号：入力必須項目です。';
				this.m_ErrorModelList.push(model);
			}
			if (this.m_ErrorModelList.length > 0) {
				validError.addError('Error');
				this.setViewMode('エラー','表示');
				this.setListData('ErrorModelList', this.m_ErrorModelList);
			}
		}
	}

	/**
	 * バリデーションエラーメッセージ通知
	 * @param name コントロール名
	 * @param rowIndex 行数(Listの場合)
	 * @param validatorName バリデーション名
	 * @param msg エラーメッセージ
	 * @returns エラーメッセージ（変更する場合に指定する、メッセージを使用しない場合は、nullを返す）
	 */
	public onInvalidMessage(name: string, rowIndex: number, validatorName: string, msg: string): string {
		if (((name === 'desiredDate1' || name === 'desiredDate2' || name === 'desiredDate3') && validatorName === '最小値')
		|| ((name === 'beforeContact' || name === 'sameDayContact') && (validatorName === '整数')))
			msg = null;
		return msg;
	}

	/**
	 * ビュー表示通知
	*/
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 次画面情報削除
			this.m_MypageWizardMngr.getCache().m_OptionConfirmList = new Array();
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			// 戸建、戸建賃貸の場合
			if (this.m_Customer.building[0].buildingTypeCD === '3' || this.m_Customer.building[0].buildingTypeCD === '2')
				this.setViewMode('階数', '表示');
			this.m_PhoneInfo = this.m_MypageWizardMngr.getCache().m_PhoneInfo;
			if (this.m_PhoneInfo.securityFlg)
				this.setViewMode('セキュリティ会社', '表示');
			// 配線状況
			if (this.m_PhoneInfo.multiModularJacks)
				this.setViewMode('配線状況', '選択')
			else
				this.setViewMode('配線状況', '未選択')
			// 再遷移時、設問出し分け
			if (this.m_PhoneInfo.phoneNumerSelect) {
				this.setQuestion();
			}
			this.m_PhoneInfo.courseCheck = true;
			this.m_PhoneInfo.equipmentProvidingCheck = true;
			this.setOtoku();	// お得パック表示処理
			this.setCourse();	// 申込みコース取得
			this.setEquipmentProviding();	// 機器のご提供方法表示処理
			this.setOptionList();
			this.refreshView();
		});
	}
	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ
	 */
	public onNext(param: any): void {
		this.refreshModel();
		// 希望場所「その他」以外の場合
		if (this.m_location.value !== 'その他')
			this.m_PhoneInfo.comment = null;
		// セキュリティ会社未加入の場合
		if (!this.m_securityFlg.value)
			this.m_PhoneInfo.securityComment = null;
		// 新しい番号を作る場合
		if (this.m_PhoneInfo.phoneNumerSelect === '1') {
			this.m_PhoneInfo.lineType = null;
			this.m_PhoneInfo.phoneNumber = null;
			this.m_PhoneInfo.contractorNameKana = null;
			this.m_PhoneInfo.contractorNameKanji = null;
			this.m_PhoneInfo.otherLineComment = null;
		}
		// 今ある番号を使う場合
		else if (this.m_PhoneInfo.phoneNumerSelect === '2') {
			if (this.m_PhoneInfo.lineType !== '7')
				this.m_PhoneInfo.otherLineComment = null;
		}
		// // au携帯電話「未使用」の場合
		if (this.m_auPhoneUsing.value === '2')
			this.m_PhoneInfo.auPhoneNumber = null;
		// オプション「着信転送」を申し込む場合
		const callForwarding = this.m_OptionListModel.filter(data => data.generalCd === '6');
		if (callForwarding[0].check)
			this.m_PhoneInfo.callForwardingFlg = true;
		else
			this.m_PhoneInfo.callForwardingFlg = false;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド  ----------------------------------------------------------
	private setOtoku(): void {
		if (!this.m_PhoneInfo.yesOtoku && !this.m_PhoneInfo.noOtoku) 
			this.m_PhoneInfo.noOtoku = true;
		const planList = this.m_MypageWizardMngr.getViewInfo().contractModel.planList;
		const planName = this.m_MypageWizardMngr.getOtokuPlanName(this, planList);
		if (ObjectUtil.isNotNullOrUndefined(planName)) {
			this.m_PhoneInfo.otokuPlan = '(※【' + planName.dispnamep + '】割引適用)';
			this.m_PhoneInfo.otokuFlg = true;
		}
		const customerPayment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
		if (this.m_MypageWizardMngr.showOtokuPlan(this, customerPayment[0].payment[0], planList))
			this.setViewMode('お得パック', 'なし');
	}

	private setCourse(): void {
		const viewInfo: ViewInfoModel = this.m_MypageWizardMngr.getViewInfo();
		// commonからIHオーナーサービスを取得
		const commonIhList: MCommon[] = this.m_MypageWizardMngr.getMCommonList(this, 'IH', 'OWNER_SCD', null, null);
		// オーナー契約情報一覧を取得
		const ownerList: Contract[] = viewInfo.ownerContractList
		// 物件プラン一覧を取得
		const buildingPlan = this.m_MypageWizardMngr.getBuildingPlan(this, ownerList);
		// 申込みコース表示処理
		let courseMdisplay: MDisplay;
		// 1.FTTH判定
		const ftth = viewInfo.customer.building[0].buildingLineTypeCD;
		let ftthFlg = false;
		if (ftth === Phoneconst.FTTH_PLAN) {
			ftthFlg = true
		}
		if (ftthFlg === true) {
			const buildingTypeCD = viewInfo.customer.building[0].buildingTypeCD;
			// 2.物件：集合
			if (buildingTypeCD === Phoneconst.APARTMENT_BUILDING) {
				// 5.FTTH光配線もしくはFTTH高速光配線
				if (buildingPlan.commonValue === Phoneconst.FTTH_OPTICAL_WIRING || buildingPlan.commonValue === Phoneconst.FTTH_HIGH_OPTICAL_WIRING) {
					courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3, '');
				} else {
					courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE2, '');
				}
			// 3.物件：戸建賃貸
			} else if (buildingTypeCD === Phoneconst.DETACHED_HOUSE_RENTAL) {
				// 6.FTTH賃貸戸建用
				if (buildingPlan.commonValue === Phoneconst.FTTH_DETACHED_HOUSE_PLAN) {
					courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3, '');
				} else {
					courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE2, '');
				}
			// 4.物件：戸建
			} else if (buildingTypeCD === Phoneconst.DETACHED_HOUSE) {
				courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE3, '');
			// 2,3,4以外
			} else {
				courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE1, '');
			}
		// FTTH以外
		} else {
			courseMdisplay = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMPHONE_SERVICE1, '');
		}
		this.m_PhoneInfo.serviceCd = courseMdisplay.namecd;
		this.m_PhoneInfo.course = courseMdisplay.dispnamep;
	}

	private setEquipmentProviding(): void {
		this.m_PhoneInfo.equipmentProviding = this.m_MypageWizardMngr.getMDisplay(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '1').dispnamep;
	}

	/**
	 * オプションリスト
	 */
	private setOptionList() {
		this.m_OptionListModel = this.m_MypageWizardMngr.getCache().m_OptionListModel;
		if (this.m_OptionListModel.length === 0) {
			const optionList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.PHONE_OPTION, '');
			for (const option of optionList) {
				const model = new PhoneInfoModel();
				model.optionName = option.name1;
				if (option.name2)
					model.notes = option.name2;
				model.optionFee = this.m_MypageWizardMngr.getTaxFee(this, String(option.num1));
				model.payUnit = '円';
				model.subCd = option.subCd1;
				model.subCd2 = option.subCd2;
				model.generalCd = option.generalCd;
				this.m_OptionListModel.push(model);
			}
		}
		else {
			const prankCall = this.m_OptionListModel.filter(data => data.check && data.optionName === '迷惑電話自動ブロック');
			if (prankCall && prankCall.length > 0)
				this.setViewMode('迷惑電話', '表示');
		}
		this.setListData('OptionListModel', this.m_OptionListModel);
		// オプションリスト(PC/SP判定)
		if (window.outerWidth > 440)
			this.setViewMode('オプション','PC');
		else
			this.setViewMode('オプション','SP');
	}

	/**
	 * 設問出し分け
	 * (画面制御)
	 */
	private changeQuestion() {
		// 新しい電話番号を使う
		if (this.m_phoneNumerSelect.value === '1') {
			this.setViewMode('固定電話情報', '非表示');
			this.setViewMode('au携帯電話', '表示');
			// au携帯電話≪利用中≫
			if (this.m_auPhoneUsing.value === '1')
				this.setViewMode('au携帯電話入力欄', '表示');
			// au携帯電話≪未使用≫
			else if (this.m_auPhoneUsing.value === '2')
				this.setViewMode('au携帯電話入力欄', '非表示');
			// 電話サービス 設備の確認
			if (this.m_auPhoneUsing.value)
				this.setViewMode('電話サービス設備', '表示');
		}
		// 今ある電話番号を使う
		else if  (this.m_phoneNumerSelect.value === '2'){
			this.setViewMode('固定電話情報', '表示');
			this.setViewMode('au携帯電話', '非表示');
			this.setViewMode('au携帯電話入力欄', '非表示');
			// 業者
			if (this.m_lineType.value || this.m_auPhoneUsing.value) {
				this.setViewMode('au携帯電話', '表示');
				// au携帯電話≪利用中≫
				if (this.m_auPhoneUsing.value === '1')
					this.setViewMode('au携帯電話入力欄', '表示');
				// au携帯電話≪未使用≫
				else if (this.m_auPhoneUsing.value === '2')
					this.setViewMode('au携帯電話入力欄', '非表示');
				// 電話サービス 設備の確認
				if (this.m_auPhoneUsing.value)
					this.setViewMode('電話サービス設備', '表示');
			}
		}
		// 各種サービスの利用
		if (this.m_noService.value || this.m_homeSecurity.value || this.m_emergencyCall.value || this.m_intercom.value ||
			this.m_homeBusinessPhone.value || this.m_gasWater.value || this.m_phoneRental.value || this.m_otherService.value) 
				this.setViewMode('電話サービス注意事項', '表示');
		else
			this.setViewMode('電話サービス注意事項', '非表示');
	}

	/**
	 * 設問出し分け
	 * (再遷移時)
	 */
	private setQuestion() {
		this.setViewMode('au携帯電話', '表示');
		this.setViewMode('電話サービス設備', '表示');
		this.setViewMode('電話サービス注意事項', '表示');
		if (this.m_PhoneInfo.phoneNumerSelect === '2')
			this.setViewMode('固定電話情報', '表示');
		if (this.m_PhoneInfo.auPhoneUsing === '1')
			this.setViewMode('au携帯電話入力欄', '表示');
		if (this.m_PhoneInfo.homeSecurity)
			this.setViewMode('備考_ホームセキュリティ', '表示');
		if (this.m_PhoneInfo.emergencyCall)
			this.setViewMode('備考_緊急通報備考', '表示');
		if (this.m_PhoneInfo.intercom)
			this.setViewMode('備考_インターフォン', '表示');
		if (this.m_PhoneInfo.homeBusinessPhone)
			this.setViewMode('備考_ホーム・ビジネステレフォン', '表示');
		if (this.m_PhoneInfo.gasWater)
			this.setViewMode('備考_ガス・水道', '表示');
		if (this.m_PhoneInfo.phoneRental)
			this.setViewMode('備考_電話機レンタル', '表示');
		if (this.m_PhoneInfo.otherService)
			this.setViewMode('備考_その他', '表示');
	}
	// --------------------------------------------------------------------------
}
