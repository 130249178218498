import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { VideoUnlimitedComfirmView } from '../videoService/video_unlimited_comfirm/VideoUnlimitedComfirmView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { AddTvComfirmView } from '../tv/new/add_tv_comfirm/AddTvComfirmView';
import { AddTvSelect1View } from '../tv/new/add_tv_select1/AddTvSelect1View';
import { AddTvSelect2View } from '../tv/new/add_tv_select2/AddTvSelect2View';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { TvCourseModel } from '../../models/mypage/tv/TvCourseModel';
import { TvBaseModel } from '../../models/mypage/tv/TvBaseModel';
import { TvOptionListModel } from '../../models/mypage/tv/TvOptionListModel';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { TvCourseListModel } from '../../models/mypage/tv/TvCourseListModel';
import { ViewInfoModel } from '../../service/models/apimodels/ViewInfoModel';
import { GetTvInfoRequest } from '../../service/models/dto/mypagerenewal/GetTvInfoRequest';
import { ItscomTvService } from '../../service/ItscomTvService';
import { ModTvequipSelectView } from '../tv/equipment/mod_tvequip_select/ModTvequipSelectView';
import { ModTvequipConfirmView } from '../tv/equipment/mod_tvequip_confirm/ModTvequipConfirmView';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { Contract } from '../../models/bss/contract/Contract';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * 動画配信サービス_見放題パックプライム_ウィザードクラス
 */
export class TvEquipWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new ModTvequipSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '機器変更', viewName: new ModTvequipSelectView().name, path: 'mod_tvequip_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '内容確認', viewName: new ModTvequipConfirmView().name, path: 'mod_tvequip_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new CommonCompleteView().name, path: 'mod_tvequip_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.commonList = new Array();
		req.commonList.push(new MCommon('FTTHコース一覧', 'srvtv', 'srvtv_ftth_course', 'course', null));
		req.displayList = new Array();
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TV_SERVICE, null));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.RENTALHOUSE_NONFD_OWNERPLAN, null));
		req.contractFlg = true;
		req.buildingFlg = true;
		req.ownerFlg = true;
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		req.customerId = mngr.getParams().customerId;
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'TV機器変更';
		});
		req.customerMailId = mailconstants.customerMail;
		req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		//契約情報取得
		const contractId = mngr.getParams().contractId;
		let contract = null;
		if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
			contract = mngr.getContract(view, contractId);
		}
		req.systemInfo.contractId = contract.contractID;
		req.systemInfo.appService = cacheData.m_TvBaseModel.course;
		req.systemInfo.rental = 'レンタル';
		req.systemInfo.stb = cacheData.m_TvBaseModel.terminal;
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		if (view.m_Name === 'ModTvequipSelectView') {
			//トリプルの場合：追加判定
			const viewCode = '001003';
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2' && mngr.isRentalHouseFd(view)) {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TvNewWizardModel {
		let cacheData = new TvNewWizardModel();
		cacheData.m_TvBaseModel = new TvBaseModel();
		cacheData.m_Contract = new Contract();
		return cacheData;
	}
	// --------------------------------------------------------------------------
}